import './_Quotation-Item.scss';
import {
  IFormTypes,
  IProject,
  IQuotation,
  ProjectFilter,
} from '../../interfaces';
import { dateToPopularString } from '../../services/utils';

interface ProjectItemProps {
  data: IQuotation;
  handleToggleForm: (
    type: IFormTypes | null,
    data: IQuotation | IProject | null
  ) => void;
  type?: keyof ProjectFilter;
}
export default function QuotationItem({ data }: ProjectItemProps) {
  const handleOpenForm = () => {
    // handleToggleForm(type, data);
  };
  const name =
    'page2' in data.data
      ? data.data.page2.strasse
      : 'project_name' in data.data
      ? data.data.project_name
      : '';

  return (
    <div onClick={handleOpenForm} className="project-item">
      <div className="project-item-container">
        <div className="item-status-container">
          <div className="item-status-main">
            <div className="item-status-value">{data.status}</div>
          </div>
        </div>
        <div className="project-item__info">
          <span>{name}</span>
          <span>
            Created Date: &nbsp;
            {dateToPopularString(new Date(data.create_date))}
          </span>
        </div>
      </div>
    </div>
  );
}
