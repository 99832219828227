import { useEffect, useState } from 'react';
import { IQuotation, ISimpleData, IVMStore } from '../../../../interfaces';
import ModalContainer from '../../ModalContainer';
import { Modal } from 'react-bootstrap';
import FloorOption from '../../../FormPages/forms/page-9/floor-option';
import './_quotation-detail-modal.scss';
import CommentBox from '../../../CommentBox';
import { Button } from '../../..';

type CombineInterfaces = Partial<IVMStore & ISimpleData> & {
  source_document: string;
};

interface QuotationDetailModalProps {
  isShow: boolean;
  handleClose: () => void;
  data: IQuotation;
}

const QuotationDetailModal = ({
  isShow,
  handleClose,
  data,
}: QuotationDetailModalProps) => {
  const [content, setContent] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [isCommenting, setIsCommenting] = useState<boolean>(false);

  const [quotationData, setQuotationData] = useState<CombineInterfaces>();
  const [dataLength, setDataLength] = useState<number>(0);

  // const [data, _] = useState<IQuotation | null>(null);

  useEffect(() => {
    if (data) {
      setQuotationData({ ...data.data, source_document: data.source_document });
      const count = Object.keys(data.data).length;
      setDataLength(count);
    }
  }, [data]);

  const onSubmitComment = async () => {
    // const quillEditor = document.querySelector('.quill .ql-editor');
    // let isBlank = false;
    // if (quillEditor) {
    //   const regex = /^<p>(\s*|<br>)<\/p>$/;
    //   isBlank = quillEditor?.innerHTML
    //     ? regex.test(quillEditor?.innerHTML)
    //     : true;
    // }
    // if (files.length || !isBlank) {
    //   try {
    //     const formData = new FormData();
    //     if (files.length) {
    //       for (let i = 0; i < files.length; i++) {
    //         formData.append('files', files[i]);
    //       }
    //     }
    //     formData.append('content', content ? content : ' ');
    //     formData.append('project_id', Number(id).toString());
    //     const res = await addProjectComment(appAxios, formData);
    //     if (res?.data) {
    //       setContent('');
    //       setFiles([]);
    //       setIsCommenting(false);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  };

  const confirmOrder = () => {
    console.log('confirm order, send email to vmt/client');
    return;
  };

  if (!quotationData) return null;
  return (
    <ModalContainer>
      <Modal show={isShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Quotation Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataLength !== 3 ? (
            <div className="summary-main">
              <div className="source-document">
                source document: {quotationData.source_document}
              </div>
              <div className="summary complicated d-flex flex-column">
                <div className="summary-container">
                  <div>
                    <p>Angaben zum Objekt</p>
                    <span>Adresse</span>
                    <span>{quotationData.page2?.strasse || '...'}</span>
                  </div>
                  <div>
                    <p>Scanaufnahme</p>
                    <span>{quotationData.page3 || '...'}</span>
                  </div>
                  <div>
                    <p>CAD Gebäudemodell</p>
                    <span>{quotationData.page4 + ' Modell' || '...'}</span>
                  </div>
                  <div>
                    <p>CAD Software</p>
                    <span>
                      {quotationData.page5?.replace('-', ' - Ausführung ') ||
                        '...'}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <p>Version Masterplan</p>
                    <span>{quotationData.page6 || '...'}</span>
                  </div>
                  <div>
                    <p>3D Vermessung Zeichnungsdetaillierung</p>
                    <span>{quotationData.page7 || '...'}</span>
                  </div>
                  <div>
                    <p>Geländedetaillierung</p>
                    <span>{quotationData.page8 || '...'}</span>
                  </div>
                  <div>
                    <p>Planlavout</p>
                    <span>Art des Plans</span>
                    {<span>{quotationData.page10?.type}</span>}
                    {quotationData.page10?.que ? (
                      <ul>
                        <li>Querschnitte: {quotationData.page10.que}</li>
                        <li>Längsschnitte: {quotationData.page10.lan}</li>
                        {quotationData.page10.fas ? (
                          <li>Fassadenansichten: {quotationData.page10.fas}</li>
                        ) : (
                          ''
                        )}
                      </ul>
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    <p>Gelände</p>
                    <span>Geländeaufnahme</span>
                    <span>{quotationData.page12 || '...'}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <p>Geschossauswah</p>
                    <div
                      className="d-flex flex-column w-100"
                      style={{ maxHeight: '500px', overflow: 'auto' }}
                    >
                      {
                        <>
                          <FloorOption
                            name="dach"
                            quantity={quotationData.page9?.dach || 0}
                          />
                          <hr />
                          <FloorOption
                            name="eg"
                            quantity={quotationData.page9?.eg || 0}
                          />
                          <hr />
                          <FloorOption
                            name="ug"
                            quantity={quotationData.page9?.ug || 0}
                          />
                        </>
                      }
                    </div>
                  </div>
                  <div>
                    <p>Datenupload</p>
                    {quotationData.page13?.length ? (
                      <ul>
                        {quotationData.page13?.map((f: File) => (
                          <li key={f.name}>{f.name}</li>
                        ))}
                      </ul>
                    ) : (
                      '...'
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <p>Schlussbemerkungen</p>
                    <span>{quotationData.page14 || '...'}</span>
                  </div>
                  <div>
                    <p>Kontaktangaben</p>
                    <div className="d-flex">
                      <div className="col">
                        <span>Firma</span>
                        <span>{quotationData.page15?.firma || '...'}</span>
                      </div>
                      <div className="col">
                        <span>Strasse/Nr.</span>
                        <span>{quotationData.page15?.strasse || '...'}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="col">
                        <span>Vorname</span>
                        <span>{quotationData.page15?.vorname || '...'}</span>
                      </div>
                      <div className="col">
                        <span>Nachname</span>
                        <span>{quotationData.page15?.nachname || '...'}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="col">
                        <span>PLZ</span>
                        <span>{quotationData.page15?.plz || '...'}</span>
                      </div>
                      <div className="col">
                        <span>Ort</span>
                        <span>{quotationData.page15?.ort || '...'}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="col">
                        <span>Email</span>
                        <span>{quotationData.page15?.email || '...'}</span>
                      </div>
                      <div className="col">
                        <span>Telefon</span>
                        <span>{quotationData.page15?.telefon || '...'}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Rechnungsadresse</p>
                    <div className="d-flex">
                      <div className="col">
                        <span>Anschrift</span>
                        <span>
                          {quotationData.page15?.r_anschrift || '...'}
                        </span>
                      </div>
                      <div className="col">
                        <span>Strasse / Nr.</span>
                        <span>{quotationData.page15?.r_strasse || '...'}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="col">
                        <span>PLZ</span>
                        <span>{quotationData.page15?.r_plz || '...'}</span>
                      </div>
                      <div className="col">
                        <span>Ort</span>
                        <span>{quotationData.page15?.r_ort || '...'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="summary-main">
              <div className="source-document">
                source document: {quotationData.source_document}
              </div>
              <div className="summary d-flex flex-md-row flex-column">
                <div className="summary-container simple">
                  <div>
                    {/* <p >Angaben zum Objekt</p>
                      <span>Name des projekts</span>
                      <span>{quotationData.page2?.strasse || '...'}</span> */}
                  </div>
                  <div>
                    <p>Name des projekts</p>
                    <span>{quotationData.project_name || '...'}</span>
                  </div>
                  <div>
                    <p>Beschreibung</p>
                    <span>
                      {quotationData.description
                        ? quotationData.description + ' Modell'
                        : '...'}
                    </span>
                  </div>
                  <div>
                    <p>Datenupload</p>
                    <span>
                      {quotationData.files?.length ? (
                        <ul>
                          {quotationData.files?.map((f: File) => (
                            <li key={f.name}>{f.name}</li>
                          ))}
                        </ul>
                      ) : (
                        '...'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isCommenting && (
            <CommentBox
              files={files}
              setFiles={setFiles}
              isCommenting={isCommenting}
              setIsCommenting={setIsCommenting}
              content={content}
              setContent={setContent}
              onSubmitComment={onSubmitComment}
            ></CommentBox>
          )}
          <div className="add-comment-container confirm-container">
            {!isCommenting && (
              <Button
                variant="btn-dark-secondary"
                margin="0 0 0 0"
                padding="4px 12px"
                border="none"
                borderRadius="4px"
                onClick={() => setIsCommenting(true)}
              >
                Add comment
              </Button>
            )}
            <Button
              variant="btn-dark-secondary"
              margin="0 0 0 auto"
              padding="4px 12px"
              border="none"
              borderRadius="4px"
              onClick={() => confirmOrder()}
            >
              Confirm
            </Button>
          </div>
          {/* <div className="confirm-container">
            <Button
              variant="btn-dark-secondary"
              margin="0 0 0 0"
              padding="4px 12px"
              border="none"
              borderRadius="4px"
              onClick={() => confirmOrder()}
            >
              Confirm
            </Button>
          </div> */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </ModalContainer>
  );
};

export default QuotationDetailModal;
