import './Input.scss';
import { useState } from 'react';

type InputProps = {
  type?: string;
  placeHolder?: string;
  padding?: string;
  width?: string;
  margin?: string;
  id?: string;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: 'on' | 'off';
  required?: boolean;
};

const Input = ({
  type,
  placeHolder,
  id,
  className,
  value,
  onChange,
  autoComplete,
  required,
  padding,
  width,
  margin,
}: InputProps) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="core-input-container">
      <input
        style={{ padding, width, margin }}
        type={!isShow ? type : 'text'}
        placeholder={placeHolder}
        id={id}
        className={className ? className : ''}
        required={required}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      {type === 'password' &&
        (isShow ? (
          <i
            onClick={() => setIsShow(!isShow)}
            className="fa-regular fa-eye"
          ></i>
        ) : (
          <i
            onClick={() => setIsShow(!isShow)}
            className="fa-regular fa-eye-slash"
          ></i>
        ))}
    </div>
  );
};

export default Input;
