import './_Project-Item.scss';
import { IFormTypes, IProject, ProjectFilter } from '../../interfaces';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { dateToPopularString } from '../../services/utils';

interface ProjectItemProps {
  data: IProject;
  handleToggleForm: (
    type: IFormTypes | null,
    data: IProject | null
  ) => void;
  type?: keyof ProjectFilter;
}

export default function ProjectItem({
  data,
  handleToggleForm,
  type = 'project',
}: ProjectItemProps) {
  const handleOpenForm = () => {
    handleToggleForm(type, data);
  };

  return (
    <div onClick={handleOpenForm} className="project-item">
      <div className="project-item-container">
        <div className={'project-item__progress'}>
          <CircularProgressbar
            value={Math.floor(data.processed_percent)}
            text={`${Math.floor(
              data?.processed_percent ? data.processed_percent : 0
            )}%`}
            counterClockwise={true}
            styles={buildStyles({
              textSize: '24px',
              textColor: 'black',
              pathColor: 'black',
            })}
            strokeWidth={2}
          />
        </div>
        <div className="project-item__info">
          <span>{data.name}</span>
          <span>
            Receive PC: &nbsp;
            {data.receive_point_cloud_date
                ? dateToPopularString(new Date(data.receive_point_cloud_date))
                : 'updating'}
          </span>
          <span>
            Start Date: &nbsp;
            {data.start_date
                ? dateToPopularString(new Date(data.start_date))
                : 'updating'}
          </span>
          {
            data.phase1_date ? <span>
            Phase 1 Date: &nbsp;
              {
                dateToPopularString(new Date(data.phase1_date))
              }
          </span> : null
          }
          {
            data.phase2_date ? <span>
            Phase 2 Date: &nbsp;
              {
                dateToPopularString(new Date(data.phase2_date))
              }
          </span> : null
          }
          {
            data.phase3_date ? <span>
            Phase 3 Date: &nbsp;
              {
                dateToPopularString(new Date(data.phase3_date))
              }
          </span> : null
          }
          <span>
            Deadline: &nbsp;
            {data.deadline
                ? dateToPopularString(new Date(data.deadline))
                : 'updating'}
          </span>

        </div>
        {/* <Link className="detail-link" to={`/project/${data.id}`}>
            Detail
          </Link> */}
      </div>
    </div>
  );
}
