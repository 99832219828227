import { useEffect, useState } from 'react';
import { CollapseBtn, InvoiceItem } from '../index.ts';
import VStore from '../../store';
import { getAllInvoice } from '../../services/APIs.ts';
import useAxios from '../../hook/useAxios.ts';
import { IInvoice } from '../../interfaces';

export default function Invoice() {
  const { projectFilter } = VStore();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  const appAxios = useAxios();

  useEffect(() => {
    getAllInvoice(appAxios)
      .then(({ data }) => {
        if (data) {
          const sortedInvoices = [...data];
          sortedInvoices.sort((a, b) => {
            return (
              new Date(b.create_date).getTime() -
              new Date(a.create_date).getTime()
            );
          });
          setInvoices(sortedInvoices);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      className={`main-list-container mb-3 ${
        projectFilter.invoice ? '' : 'hide'
      }`}
    >
      <CollapseBtn
        title={'invoice'}
        isCollapsed={isCollapsed}
        setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
      />
      <div className={`list-items-container ${isCollapsed ? 'collapse' : ''}`}>
        {invoices.map((invoice) => (
          <InvoiceItem data={invoice} key={invoice.create_date.toString()} />
        ))}
      </div>
    </div>
  );
}
