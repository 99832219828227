import './_page-15.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page15() {
  const name = 'page15';

  const { data, setData } = useVMStore();

  const _data = data[name];

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <Form>
        <Form.Group>
          <Form.Control
            placeholder={'Firma'}
            value={_data.firma}
            onChange={(e) => setData({ ..._data, firma: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-sm-row">
          <Form.Control
            className="col"
            placeholder={'Vorname*'}
            value={_data.vorname}
            onChange={(e) => setData({ ..._data, vorname: e.target.value })}
          />
          <Form.Control
            className="col"
            placeholder={'Nachname*'}
            value={_data.nachname}
            onChange={(e) => setData({ ..._data, nachname: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder={'Strasse / Nr.'}
            value={_data.strasse}
            onChange={(e) => setData({ ..._data, strasse: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-sm-row">
          <Form.Control
            className="col"
            placeholder={'PLZ*'}
            type="number"
            value={_data.plz.toString().replace(/^0+/, '')}
            onChange={(e) =>
              setData({ ..._data, plz: parseInt(e.target.value) | 0 })
            }
          />
          <Form.Control
            className="col"
            placeholder={'Ort*'}
            value={_data.ort}
            onChange={(e) => setData({ ..._data, ort: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder={'E-mail*'}
            value={_data.email}
            type="email"
            onChange={(e) => setData({ ..._data, email: e.target.value })}
            onInvalid={() => console.log('bbbb')}
          />
          {validateEmail(_data.email) || _data.email === '' ? (
            ''
          ) : (
            <span className="warning-text">*Das ist ein Pflichtfeld</span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder={'Telefon*'}
            value={_data.telefon}
            onChange={(e) => setData({ ..._data, telefon: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Switch
            className="ms-5"
            id="rechnungsadresse"
            label="Rechnungsadresse abweichend"
            checked={_data.rechnungsadresse}
            onChange={() =>
              setData({ ..._data, rechnungsadresse: !_data.rechnungsadresse })
            }
          />
        </Form.Group>
      </Form>
      {_data.rechnungsadresse ? (
        <Form className="rechnungsadresse">
          <Form.Group>
            <Form.Control
              placeholder="Anschrift"
              value={_data.r_anschrift}
              onChange={(e) =>
                setData({ ..._data, r_anschrift: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              placeholder={'Strasse / Nr.'}
              value={_data.r_strasse}
              onChange={(e) => setData({ ..._data, r_strasse: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="d-flex">
            <Form.Control
              className="col"
              placeholder={'PLZ*'}
              type="number"
              value={_data.r_plz.toString().replace(/^0+/, '')}
              onChange={(e) =>
                setData({ ..._data, r_plz: parseInt(e.target.value) | 0 })
              }
            />
            <Form.Control
              className="col"
              placeholder={'Ort*'}
              value={_data.r_ort}
              onChange={(e) => setData({ ..._data, r_ort: e.target.value })}
            />
          </Form.Group>
        </Form>
      ) : (
        ''
      )}
    </div>
  );
}
