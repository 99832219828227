import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';
import { ChangeEvent } from 'react';
import './_page-1-1.scss';
export default function Page1_1() {
  // const [curData, setCurData] = useState();
  const { simpleData, setSimpleData } = useVMStore((state) => ({
    simpleData: state.simpleData,
    setSimpleData: state.setSimpleData,
  }));
  // console.log(simpleData);
  // let _data = data[name];
  // useEffect(() => {
  //   _data = data[name];
  // }, [data[name].files]);

  function changeData(val: string, valName: 'project_name' | 'description') {
    // const newData = { ...data, [name]: val };
    setSimpleData(valName, val);
    // setData(newData);
  }

  const limitSize = 20 * 1024 * 1024;

  const handleUploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileList: File[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        fileList.push(event.target.files[i]);
      }
      // const files = event.target.files;
      if (fileList) {
        let sumSize = 0;
        const length = fileList.length;
        if (length) {
          for (let i = 0; i < length; i++) {
            sumSize += fileList[i].size;
          }
          if (sumSize > limitSize) {
            alert('Files size must be less than 20MB');
            return;
          } else {
            fileList && setSimpleData('files', fileList);
          }
        }
      }
    }
  };

  return (
    <Form className="page-1-1">
      <Form.Group>
        <Form.Control
          autoComplete="off"
          placeholder={'Name des Projekts'}
          name="project_name"
          value={simpleData.project_name}
          onChange={(e) => changeData(e.target.value, 'project_name')}
        />
      </Form.Group>
      <div className="d-flex">
        <Form.Group className="col">
          <Form.Control
            autoComplete="off"
            name="description"
            as="textarea"
            rows={5}
            placeholder="Beschreibung"
            value={simpleData.description}
            onChange={(e) => changeData(e.target.value, 'description')}
          />
        </Form.Group>
      </div>
      <div className="d-flex flex-column align-items-center w-100">
        <div className="w-100">
          <div className="vm-input-file justify-content-between align-items-center position-relative">
            <span>Dateien hierher ziehen (Max. Dateigrösse: 20 MB)</span>
            <div
              className="highlighted-btn vm-rounded text-center"
              style={{ padding: '0.75em' }}
            >
              <span>Dateien wählen</span>
            </div>
            <input type="file" multiple={true} onChange={handleUploadFiles} />
          </div>
        </div>
        <p className="mt-3 mb-3 text-center w-100">
          Es können keine Dateien hochgeladen werden, die größer als 20 MB sind
          {/* Sollten Sie mehr als 20 MB hochladen wollen, können Sie dies gerne
          unter
          <a href="https://vermessung-digital.wetransfer.com/" target="_blank">
            www.vermessungdigital.wetransfer.com
          </a>
          tun */}
        </p>
        {simpleData?.files?.length ? (
          <div className="vm-input-file mt-3">
            <ol>
              {simpleData.files.map((f) => (
                <li key={f.name}>{f.name}</li>
              ))}
            </ol>
          </div>
        ) : (
          ''
        )}
      </div>
    </Form>
  );
}
