import './_page-14.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page14() {
  const name = 'page14';

  const { data, setData } = useVMStore();

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          value={data[name]}
          as="textarea"
          rows={10}
          placeholder="Beschreibungstext..."
          onChange={(e) => setData(e.target.value)}
        />
      </Form.Group>
    </Form>
  );
}
