import { useLocation } from 'react-router-dom';
import './_header.scss';
import { Container, ListGroupItem, Navbar } from 'react-bootstrap';
import VStore from '../../store';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../hook/useAxios';
import { getLogout } from '../../services/APIs';
import { useAuthContext } from '../../hook/useAuthContext';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const appAxios = useAxios();
  const { user, updateUser } = useAuthContext();
  const { logout, curPageIdx, data, setCurPageIdx, resetData } = VStore();
  // const { logout, curPageIdx, data, setCurPageIdx, resetData } = VStore(
  //   (state) => ({
  //     logout: state.logout,
  //     curPageIdx: state.curPageIdx,
  //     data: state.data,
  //     setCurPageIdx: state.setCurPageIdx,
  //     resetData: state.resetData,
  //   }),
  //   shallow
  // );

  function handleLogout() {
    getLogout(appAxios).then(() => {
      logout();
      document.cookie =
        'img_url=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      updateUser(null);
      navigate('/login');
    });
  }

  const handleClickNavbarItem = (nav: string) => {
    if (curPageIdx === 1) {
      if (!data.page1) {
        navigate(nav);
      } else {
        const rs = window.confirm(
          'Changes may not be saved. Do you want to leave this page?'
        );
        if (rs) {
          resetData();
          navigate(nav);
        } else {
          return;
        }
      }
    } else {
      const rs = window.confirm(
        'Changes may not be saved. Do you want to leave this page?'
      );
      if (rs) {
        resetData();
        navigate(nav);
        setCurPageIdx(1);
      } else {
        return;
      }
    }
  };

  return (
    <header className="header">
      <Navbar className="navbar-main" bg="light" variant="dark">
        <Container className="nav-container">
          <div className={`navbar-main-menu`}>
            <ListGroupItem>
              <span
                onClick={() => handleClickNavbarItem('/')}
                className={`navbar-item-btn ${
                  location?.pathname === '/' ? 'active' : ''
                }`}
              >
                Home
              </span>
              <span
                onClick={() => handleClickNavbarItem('/news')}
                className={`navbar-item-btn ${
                  location?.pathname === '/news' ? 'active' : ''
                }`}
              >
                News
              </span>
              <span
                onClick={() => handleClickNavbarItem('/')}
                className={`navbar-item-btn ${
                  location?.pathname === '/about' ? 'active' : ''
                }`}
              >
                About
              </span>
            </ListGroupItem>
          </div>
          <Navbar.Brand>
            <div className="navbar-user-infor">
              <div className="user-img">
                <img
                  src={user?.imageUrl || `/assets/images/user.jpeg`}
                  alt=""
                />
                <div className="sub-menu">
                  <div onClick={handleLogout} className="navbar-item-btn img">
                    Logout
                  </div>
                </div>
              </div>
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
}
