import { useState } from 'react';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';
import FormSelect from '../../../form-select';
import './_page-7.scss';

export default function Page7() {
  const name = 'page7';

  const { data, setData, showDetailModel } = useVMStore();

  const types = [
    'Decken',
    'Dächer',
    'Fassaden',
    'Geländer',
    'Metallträger',
    'Türen & Fenster',
    'Vermaßung',
  ];

  const value = [
    {
      val: 'Standard',
      level: 1,
    },
    {
      val: 'Hoch',
      level: 2,
    },
    {
      val: 'Premium',
      level: 3,
    },
  ];

  const [curType, SetCurType] = useState<string>(types[0]);

  function getImageSrc(level: number, format = 'png') {
    const _curType = curType
      .replaceAll('ä', 'a')
      .replaceAll('ü', 'u')
      .replaceAll('ß', 'b');
    return `./assets/3d-images/${_curType}/${_curType} L${level}.${format}`;
  }

  return (
    <Form className="d-flex flex-column">
      <div className="select-container d-flex justify-content-center">
        <FormSelect
          data={types}
          value={curType}
          size="sm"
          setValue={SetCurType}
        />
      </div>

      <div className="d-flex justify-content-between mt-3 flex-column flex-md-row">
        {value.map((v) => (
          <Form.Group
            className="d-flex flex-column align-items-center w-100"
            key={'page_7_op' + v.val}
            controlId={'page_7_' + v.val}
          >
            <label onClick={() => showDetailModel(getImageSrc(v.level))}>
              <img
                className="expand-btn"
                src="./assets/images/expand.svg"
                alt="expand"
              />
              <img
                src={getImageSrc(v.level)}
                alt={curType + ' level ' + v.level}
              />
            </label>
            <Form.Check
              type="radio"
              className="mt-3"
              label={v.val}
              value={v.val}
              checked={data[name] === v.val}
              onChange={(e) => {
                setData(e.target.value);
              }}
            />
          </Form.Group>
        ))}
      </div>
    </Form>
  );
}
