import {Route, Routes} from 'react-router-dom';
import Layout from './layouts/Layout.tsx';
import {AddNews, AddRequest, Home, InquiryForm, Login, News, NewsDetail} from './pages';
import './assets/styles/App.scss';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="/add-request" element={<AddRequest/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/news/:id" element={<NewsDetail/>}/>
                {/* <Route path="/admin/news" element={<NewsAdmin />} /> */}
                <Route path="/admin/news/add-news" element={<AddNews/>}/>
                <Route path="/admin/news/edit-news/:id" element={<AddNews/>}/>
                <Route path={"/inquiry-form"} element={<InquiryForm/>}/>
            </Route>
            <Route path="login" element={<Login/>}/>
            <Route path="*" element={<h1>Not Found</h1>}/>
        </Routes>
    );
}

export default App;
