import { useEffect, useState } from 'react';
import './_page.scss';
import { IPageName } from '../../interfaces';
import { Pages } from './forms';
import Title from '../Title';
import useVMStore from '../../store';
import { Modal } from 'react-bootstrap';

export default function Page() {
  const { curPageIdx, detailModel, showDetailModel } = useVMStore();

  const [page, setPage] = useState<IPageName | 'page16' | 'page1-1'>('page1');

  useEffect(() => {
    if (curPageIdx === 1.1) {
      setPage('page1-1');
    } else {
      setPage(('page' + curPageIdx) as IPageName);
    }
  }, [curPageIdx]);

  function switchPage() {
    switch (page) {
      case 'page1': {
        return <Pages.Page1 />;
      }
      case 'page1-1': {
        return <Pages.Page1_1 />;
      }
      case 'page2': {
        return <Pages.Page2 />;
      }
      case 'page3': {
        return <Pages.Page3 />;
      }
      case 'page4': {
        return <Pages.Page4 />;
      }
      case 'page5': {
        return <Pages.Page5 />;
      }
      case 'page6': {
        return <Pages.Page6 />;
      }
      case 'page7': {
        return <Pages.Page7 />;
      }
      case 'page8': {
        return <Pages.Page8 />;
      }
      case 'page9': {
        return <Pages.Page9 />;
      }
      case 'page10': {
        return <Pages.Page10 />;
      }
      case 'page12': {
        return <Pages.Page12 />;
      }
      case 'page13': {
        return <Pages.Page13 />;
      }
      case 'page14': {
        return <Pages.Page14 />;
      }
      case 'page15': {
        return <Pages.Page15 />;
      }
      case 'page16': {
        return (
          <div className="d-flex flex-column">
            <small className="ms-3">
              <i>
                *Durch absenden des Formlars erklären Sie sich damit
                einverstanden, dass wir Ihre personenbezogenen Daten verarbeiten
                und speichern dürfen.
              </i>
            </small>
            <Pages.Page16 />
          </div>
        );
      }
      default: {
        return '';
      }
    }
  }

  return (
    <section className={'page ' + page.replace('page', 'page-')}>
      <Title page={page}></Title>
      <div className="vm-flex-grow">
        <div>
          <div>{switchPage()}</div>
        </div>
      </div>
      <Modal
        show={detailModel !== ''}
        onHide={() => showDetailModel('')}
        size="lg"
        centered={true}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="w-100">
            <img src={detailModel} alt="Detail" className="w-100" />
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}
