import './_page-4.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';
export default function Page4() {
  const name = 'page4';

  const { data, setData } = useVMStore();

  const value = [
    {
      label: '3D',
      val: '3D',
      imgSrc: './assets/images/3D.webp',
    },
    {
      label: '2D',
      val: '2D',
      imgSrc: './assets/images/2D.webp',
    },
  ];

  return (
    <Form className="d-flex justify-content-between flex-column flex-sm-row">
      {value.map((v) => (
        <div
          className="d-flex flex-column align-items-center mb-3 mb-sm-0"
          key={'page_3_op' + v.val}
        >
          <label htmlFor={'cad_' + v.val}>
            <img src={v.imgSrc} alt={v.val} />
          </label>
          <Form.Check
            type="radio"
            id={'cad_' + v.val}
            className="mt-3"
            label={v.label}
            value={v.val}
            checked={data[name] === v.val}
            onChange={(e) => {
              setData(e.target.value);
            }}
          />
        </div>
      ))}
    </Form>
  );
}
