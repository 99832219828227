import './_page-6.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page6() {
  const name = 'page6';

  const { data, setData } = useVMStore();

  const values = [
    '3D Vermessung Digital Standardvorlage',
    'Bürointerne Zeichnungsvorlage',
  ];

  return (
    <Form className="d-flex flex-column flex-md-row">
      {values.map((v, idx) => (
        <Form.Check
          type="radio"
          key={'page_6_op_' + idx}
          id={'page_6_op_' + idx}
          className="mb-3 col"
          label={v}
          value={v}
          checked={data[name] === v}
          onChange={(e) => {
            setData(e.target.value);
          }}
        />
      ))}
    </Form>
  );
}
