import React from 'react';
import ReactQuill from 'react-quill';
import { Button } from '..';
import { ChangeEvent } from 'react';
import './_comment-box.scss';

interface ICommentBoxProps {
  files: File[];
  isCommenting: boolean;
  content: string;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setIsCommenting: React.Dispatch<React.SetStateAction<boolean>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  onSubmitComment: () => void;
}

const CommentBox = ({
  files,
  setFiles,
  isCommenting,
  setIsCommenting,
  content,
  setContent,
  onSubmitComment,
}: ICommentBoxProps) => {
  const handleRenderUploadingFile = (file: File) => {
    const fileExt = file.name.split('.').pop();
    if (
      fileExt === 'jpg' ||
      fileExt === 'png' ||
      fileExt === 'jpeg' ||
      fileExt === 'gif' ||
      fileExt === 'svg' ||
      fileExt === 'webp'
    ) {
      const imgUrl = URL.createObjectURL(file);
      // console.log(imgUrl);
      return <img src={imgUrl} />;
    } else
      return (
        <div className="file-download-container">
          {fileExt === 'pdf' ? (
            <i className="file-logo fa-regular fa-file-pdf"></i>
          ) : fileExt === 'doc' || fileExt === 'docx' ? (
            <i className="file-logo fa-regular fa-file-word"></i>
          ) : fileExt === 'xls' || fileExt === 'xlsx' ? (
            <i className="file-logo fa-regular fa-file-excel"></i>
          ) : fileExt === 'zip' || fileExt === 'rar' ? (
            <i className="file-logo fa-regular fa-file-zipper"></i>
          ) : fileExt === 'csv' ? (
            <i className="file-logo fa-solid fa-file-csv"></i>
          ) : fileExt === 'ppt' || fileExt === 'pptx' ? (
            <i className="file-logo fa-regular fa-file-powerpoint"></i>
          ) : fileExt === 'mp4' || fileExt === 'avi' || fileExt === 'mov' ? (
            <i className="file-logo fa-regular fa-file-video"></i>
          ) : fileExt === 'mp3' || fileExt === 'wav' ? (
            <i className="file-logo fa-regular fa-file-audio"></i>
          ) : fileExt === 'js' ||
            fileExt === 'py' ||
            fileExt === 'cpp' ||
            fileExt === 'css' ||
            fileExt === 'html' ||
            fileExt === 'env' ? (
            <i className="file-logo fa-regular fa-file-code"></i>
          ) : (
            <i className="file-logo fa-regular fa-file"></i>
          )}
        </div>
      );
  };

  const handleUploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let sumSize = 0;
      const length = files.length;
      if (length) {
        for (let i = 0; i < length; i++) {
          sumSize += files[i].size;
        }
        if (sumSize > 1024 * 1024 * 20) {
          alert('Files size must be less than 20MB');
          return;
        } else {
          files && setFiles([...files]);
        }
      }
    }
  };

  const handleRemoveFile = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    fileName: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles: File[] = files;
    newFiles.length &&
      files.forEach((file, index) => {
        if (file.name === fileName) {
          newFiles.splice(index, 1);
          setFiles([...newFiles]);
        }
      });
  };

  return (
    <div>
      {isCommenting && (
        <div className="self-comment-container">
          <div className="self-comment-main">
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['clean'],
                ],
              }}
            />
            <div className="file-upload-container">
              <div className="label-file-container">
                <label className="label-file" htmlFor="file-upload-input">
                  {files.length === 0 && <i className="fa-solid fa-upload"></i>}
                  <div className="file-item-instances">
                    <div className="comment-item-files">
                      {files.length > 0 &&
                        files.map((file: File) => {
                          return (
                            <div
                              key={Math.random()}
                              className="file-commented-container"
                            >
                              <span
                                className="remove-file-button"
                                onClick={(
                                  e: React.MouseEvent<
                                    HTMLSpanElement,
                                    MouseEvent
                                  >
                                ) => {
                                  handleRemoveFile(e, file.name);
                                }}
                              >
                                <i className="fa-solid fa-circle-xmark"></i>
                              </span>
                              <div className="file-name">{file.name}</div>
                              {handleRenderUploadingFile(file)}
                              {/* {handleRenderFile(fileUrl)} */}
                              {/* <img src={fileUrl} alt="" /> */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </label>
                <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    return handleUploadFiles(e);
                  }}
                  multiple={true}
                  type="file"
                  className="file-upload-input"
                  id="file-upload-input"
                  name="file-comment"
                />
              </div>
            </div>
            <div className="submit-container">
              <Button
                onClick={() => (
                  setIsCommenting(false), setContent(''), setFiles([])
                )}
                type="button"
                margin="12px 10px 0px 0px"
                borderRadius="4px"
                variant="btn-gray-primary"
                border="none"
                padding="4px 20px"
                fontWeight="500"
              >
                Cancel
              </Button>
              <Button
                onClick={() => onSubmitComment()}
                type="button"
                margin="12px 0px 0px 0px"
                borderRadius="4px"
                variant="btn-dark-secondary"
                border="none"
                padding="4px 20px"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentBox;
