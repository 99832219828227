export default function ThankPage() {
  return (
    <div className="thank-page d-flex flex-column align-items-center p-3">
      <h1 className="text-center">
        Thank you for your interest in our services!
      </h1>
      <div className="w-50 m-3" style={{ maxWidth: '200px' }}>
        <img src="./assets/images/success.svg" alt="" />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <p>
            We have received your request for a quotation and are reviewing it
            now. Our team will be in touch with you shortly to discuss your
            needs and provide a customized quotation.
          </p>
          <p>
            If you have any questions or need further assistance, please don't
            hesitate to contact us.
          </p>
          <p>
            Thank you again for considering our services. We look forward to
            working with you soon!
          </p>
          <p>
            Best regards,
            <br />
            3D Vermessung Digital.
          </p>
        </div>
      </div>
    </div>
  );
}
