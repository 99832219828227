import {useLocation, useNavigate} from 'react-router-dom';
import axios from '../api/axios';
import VStore from '../store';

const UseRefreshToken = async () => {
  const { setCredentials } = VStore();

  const navigate = useNavigate();
  const location = useLocation();

  return async () => {
    try {
      const response = await axios.get('/auth/refresh-token', {
        withCredentials: true,
      });
      if (response.status !== 200 && !response.data.accessToken) {
        if (response.status === 401) {
          navigate('/login', {state: {from: location}, replace: true});
        }
        throw new Error('Error refreshing token');

      } else {
        setCredentials(response.data.accessToken);
      }
      return response.data.accessToken;
    } catch (error: any) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  };
};

export default UseRefreshToken;
