export type CollapseBtnProps = {
    title: string;
    isCollapsed: boolean;
    setIsCollapsed: () => void;
}

export default function CollapseBtn({title, isCollapsed, setIsCollapsed}: CollapseBtnProps) {
    return (<div className="header-title p-2 d-flex justify-content-between align-items-center w-100">
        <h3 className="text-center w-100">{title.toUpperCase()}</h3>
        <span onClick={setIsCollapsed}
              className={`toggle-icon-container ${isCollapsed ? '' : 'down'}`}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </span>
    </div>);
}
