import './_project-detail-modal.scss';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import VStore from '../../../../store';
import {
  addProjectComment,
  getLogout,
  getProjectComments,
} from '../../../../services/APIs';
import useAxios from '../../../../hook/useAxios';
import { IComment, IProject } from '../../../../interfaces';
import ModalContainer from '../../ModalContainer';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { dateToPopularString } from '../../../../services/utils';
import { Button } from '../../..';
import CommentItem from '../../../Comments/CommentItem';
import CommentBox from '../../../CommentBox';

interface QuotationDetailModalProps {
  isShow: boolean;
  handleClose: () => void;
  data: IProject;
}

const ProjectDetailModal = ({
  isShow,
  handleClose,
  data,
}: QuotationDetailModalProps) => {
  const appAxios = useAxios();
  const navigate = useNavigate();
  const [content, setContent] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [isCommenting, setIsCommenting] = useState<boolean>(false);
  // loaded data
  const [comments, setComments] = useState<IComment[] | null>();

  const { logout } = VStore((state) => ({
    logout: state.logout,
  }));

  // const [data, _] = useState<IProject | null>(null)

  const onSubmitComment = async () => {
    const quillEditor = document.querySelector('.quill .ql-editor');
    let isBlank = false;
    if (quillEditor) {
      //  checking if the editor is empty
      const regex = /^<p>(\s*|<br>)<\/p>$/;
      isBlank = quillEditor?.innerHTML
        ? regex.test(quillEditor?.innerHTML)
        : true;
    }
    if (files.length || !isBlank) {
      //  if the editor is not empty or there is at least one file
      try {
        const formData = new FormData();

        if (files.length) {
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
          }
        }

        formData.append('content', content ? content : ' ');
        formData.append('project_id', Number(data!.id).toString());
        formData.append('project_name', Number(data!.name).toString());
        const res = await addProjectComment(appAxios, formData);
        if (res?.data) {
          setContent('');
          setFiles([]);
          setIsCommenting(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (data && data.id) {
      getProjectComments(appAxios, data.id)
        .then((res) => {
          if (res.data) {

            setComments(res.data.sort((a: IComment, b: IComment) => {
                return new Date(b.create_date).getTime() - new Date(a.create_date).getTime()
            }));
          }
        })
        .catch((err) => {
          if (err) {
            if (err?.response?.status === 404) {
              getLogout(appAxios)
                .then(() => {
                  logout();
                  navigate('/login');
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
    }
  }, [isCommenting]);

  const handleDownloadFile = async (fileUrl: string) => {
    try {
      const fileName = fileUrl.split('\\').pop();
      if (fileName) {
        // if (fileName?.split('.')?.length < 2) {
        //   fileName = 'anonymous' + fileName;
        // }
        // const newUrl = fileName?.split('.')[0] + fileName;
        const response = await appAxios.get(fileUrl, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log('Error downloading file:', error);
    }
  };

  const handleRenderFile = (fileUrl: string) => {
    const fileExt = fileUrl.split('.').pop();
    if (
      fileExt === 'jpg' ||
      fileExt === 'png' ||
      fileExt === 'jpeg' ||
      fileExt === 'gif' ||
      fileExt === 'svg' ||
      fileExt === 'webp'
    ) {
      return <img src={fileUrl} />;
    } else {
      return (
        <div className="file-download-container">
          {/* <i className=" fa-regular fa-file"></i>
          {fileUrl.split('\\').pop()}
          <div className="file-name">{fileUrl.split('\\').pop()}</div> */}
          {fileExt === 'pdf' ? (
            <i className="file-logo fa-regular fa-file-pdf"></i>
          ) : fileExt === 'doc' || fileExt === 'docx' ? (
            <i className="file-logo fa-regular fa-file-word"></i>
          ) : fileExt === 'xls' || fileExt === 'xlsx' ? (
            <i className="file-logo fa-regular fa-file-excel"></i>
          ) : fileExt === 'zip' || fileExt === 'rar' ? (
            <i className="file-logo fa-regular fa-file-zipper"></i>
          ) : fileExt === 'csv' ? (
            <i className="file-logo fa-solid fa-file-csv"></i>
          ) : fileExt === 'ppt' || fileExt === 'pptx' ? (
            <i className="file-logo fa-regular fa-file-powerpoint"></i>
          ) : fileExt === 'mp4' || fileExt === 'avi' || fileExt === 'mov' ? (
            <i className="file-logo fa-regular fa-file-video"></i>
          ) : fileExt === 'mp3' || fileExt === 'wav' ? (
            <i className="file-logo fa-regular fa-file-audio"></i>
          ) : fileExt === 'js' ||
            fileExt === 'py' ||
            fileExt === 'cpp' ||
            fileExt === 'css' ||
            fileExt === 'html' ||
            fileExt === 'env' ? (
            <i className="file-logo fa-regular fa-file-code"></i>
          ) : (
            <i className="file-logo fa-regular fa-file"></i>
          )}
        </div>
      );
    }
  };

  // <i className="fa-regular fa-file"></i>
  // if (!project) {
  //   return <></>;
  // } else
  return (
    <ModalContainer>
      <Modal show={isShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Project Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && (
            <div className="detail-page">
              <div className="detail-container">
                <div className="detail-infor-container">
                  <div className="detail-infor">
                    <div className="project-name">{data.name}</div>
                    <div className="infor-details">
                      <div className="infor-detail-item">
                        <div className="infor-detail-title">Deadline</div>
                        <div className="infor-detail-value">
                          {dateToPopularString(new Date(data.deadline))}
                        </div>
                      </div>
                      <div className="infor-detail-item">
                        <div className="infor-detail-title">Progress</div>
                        <div className="infor-detail-value">
                          <div
                            className={`progress-bar
                           ${data.processed_percent < 25 ? 'less' : ''}
                          `}
                            // className={`progress-bar`}
                          >
                            <div
                              className="progress-bar-inner"
                              style={{ width: `${data.processed_percent}%` }}
                            >
                              <span className="percent">
                                {Math.floor(data.processed_percent)}%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isCommenting && (
                  <CommentBox
                    files={files}
                    setFiles={setFiles}
                    isCommenting={isCommenting}
                    setIsCommenting={setIsCommenting}
                    content={content}
                    setContent={setContent}
                    onSubmitComment={onSubmitComment}
                  ></CommentBox>
                )}
                <div className="comments-all">
                  {!isCommenting && (
                    <div className="add-comment-container">
                      <Button
                        variant="btn-dark-secondary"
                        margin="0 0 12px 0"
                        padding="4px 12px"
                        border="none"
                        borderRadius="4px"
                        onClick={() => setIsCommenting(true)}
                      >
                        Add comment
                      </Button>
                    </div>
                  )}
                  <div className="comments-container-main">
                    <div className="comments-container">
                      {comments ? ( // when comments is loaded
                        comments?.length > 0 &&
                        comments.map((cmt: IComment) => {
                          return (
                            <CommentItem
                              key={cmt._id}
                              cmt={cmt}
                              handleRenderFile={handleRenderFile}
                              handleDownloadFile={handleDownloadFile}
                            />
                          );
                        })
                      ) : (
                        <>
                          {/* <div className="comments-skeleton-items">
                        {new Array(2).fill(0).map((_) => {
                          return (
                            <div
                              key={Math.random()}
                              className="comments-skeleton-item"
                            >
                              <div className="comment-user-container">
                                <Skeleton
                                  borderRadius="6px"
                                  width="40px"
                                  height="40px"
                                />
                              </div>
                              <div className="comment-main-content">
                                <div className="comment-item-content">
                                  <Skeleton count={3} />
                                </div>
                                <div className="comment-title">
                                  <Skeleton width="35px" />
                                </div>
                                <div className="comment-item-files">
                                  <Skeleton width="50px" height="50px" />
                                  <Skeleton width="50px" height="50px" />
                                  <Skeleton width="50px" height="50px" />
                                  <Skeleton width="50px" height="50px" />
                                </div>
                                <div className="additional-infor-container">
                                  <div className="additional-infor created-date">
                                    <div className="infor-title">
                                      <Skeleton />
                                    </div>
                                    <div className="infor-value">
                                      <Skeleton />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </ModalContainer>
  );
};

export default ProjectDetailModal;
