import './_page-9.scss';
import { Form } from 'react-bootstrap';
import FloorOption from './floor-option';
import useVMStore from '../../../../store';

export default function Page9() {
  const name = 'page9';

  const { data, setData } = useVMStore();

  const _data = data[name];

  function changeQuantity(floor: 'dach' | 'eg' | 'ug', val: number) {
    const newData = { ..._data };
    if (val > 0 || newData[floor] > 0) {
      newData[floor] += val;
      setData(newData);
    }
  }

  return (
    <Form className="d-flex justify-content-between">
      <div className="d-flex flex-column w-100">
        {Object.keys(_data).map((d, idx) => {
          const floor = d as 'dach' | 'eg' | 'ug';
          return (
            <div key={'floor_' + d}>
              {idx === 1 ? <hr /> : ''}
              <FloorOption
                name={floor}
                quantity={_data[floor]}
                increase={() => changeQuantity(floor, 1)}
                decrease={() => changeQuantity(floor, -1)}
              />
              {idx === 1 ? <hr style={{ borderStyle: 'solid' }} /> : ''}
            </div>
          );
        })}
      </div>
    </Form>
  );
}
