import { ProjectFilter } from '../../interfaces';
import './_type-filter.scss';
import VStore from '../../store';

const typeOptions: {
  value: keyof ProjectFilter;
  label: 'Quotation' | 'Project' | 'Invoice';
}[] = [
  { value: 'quotation', label: 'Quotation' },
  { value: 'project', label: 'Project' },
  { value: 'invoice', label: 'Invoice' },
];

const TypeFilter = () => {
  const { projectFilter, changeProjectFilter } = VStore();

  return (
    <div className="filter-container">
      {typeOptions.map((filter) => {
        return (
          <div
            onClick={() => changeProjectFilter(filter.value)}
            key={filter.value}
            className={`filter-item ${
              projectFilter[filter.value] ? 'active' : ''
            }`}
          >
            <div className="filter-value">{filter.label}</div>
          </div>
        );
      })}
    </div>
  );
};

TypeFilter.propTypes = {};

export default TypeFilter;
