import './_title.scss';
import { IPageName } from '../../interfaces';

const PageTitles: {
  [name in IPageName | 'page16']: {
    title: string;
    subTitle?: string[];
    subPages?: { title: { title: string; subTitle?: string[] } }[];
  };
} = {
  page1: {
    title: 'Wählen Sie die Art der Informationen',
    subTitle: [
      'Bitte wählen Sie die Art der Informationen aus, die Sie ausfüllen möchten',
    ],
    subPages: [
      {
        // page-1-1
        title: {
          title: 'Grundinformation',
          subTitle: ['Hintergrundinformationen und Daten zum Projekt'],
        },
      },
    ],
  },
  page2: {
    title: 'Angaben zum Objekt',
    subTitle: ['Adresse des aufzunehmenden Gebäudes'],
  },
  page3: {
    title: 'Scanaufnahme',
  },
  page4: {
    title: 'CAD Gebäudemodell',
    subTitle: ['In welcher Dimension planen Sie Ihr Projekt?'],
  },
  page5: {
    title: 'CAD Software',
    subTitle: ['Welche CAD Software nutzen Sie?'],
  },
  page6: {
    title: 'Masterplan',
    subTitle: ['Welchen Detaillierungsgrad wünschen Sie?'],
  },
  page7: {
    title: '3DVD Zeichnungsdetaillierung',
    subTitle: ['Welchen Detaillierungsgrad wünschen Sie?'],
  },
  page8: {
    title: 'Geländedetaillierung',
    subTitle: ['Welchen Detaillierungsgrad wünschen Sie?'],
  },
  page9: {
    title: 'Geschossauswahl',
    subTitle: ['Aufzunehmende Geschosse auswahlen'],
  },
  page10: {
    title: 'Planlayout',
  },
  page12: {
    title: 'Gelände',
    subTitle: ['Welche Geländeaufnahme wünschen Sie?'],
  },
  page13: {
    title: 'Datenupload',
    subTitle: [
      'Senden Sie uns alle vorhandenen BestandEspläne , Skizzen oder Fotos',
    ],
  },
  page14: {
    title: 'Schlussbemerkungen',
  },
  page15: {
    title: 'Kontaktangaben',
  },
  page16: {
    title: 'Zusammenfassung',
  },
};

export type TitleProps = {
  page: IPageName | 'page16' | 'page1-1';
};

export default function Title({ page }: TitleProps) {
  let title: { title: string; subTitle?: string[] } = { title: '' };
  if (page === 'page1-1') {
    if (PageTitles['page1'].subPages?.length) {
      title = PageTitles['page1'].subPages[0].title;
    } else title = PageTitles['page1'];
  } else {
    title = PageTitles[page];
  }

  return (
    <div className="title">
      <h1>{title.title}</h1>
      {title.subTitle && title.subTitle.length
        ? title.subTitle?.map((st) => <h6 key={st}>{st}</h6>)
        : ''}
    </div>
  );
}
