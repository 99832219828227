import './_page-10.scss';
import { Form } from 'react-bootstrap';
import FormSelect from '../../../form-select';
import useVMStore from '../../../../store';
import { IPage10 } from '../../../../interfaces';

export default function Page10() {
  const name = 'page10';

  const { data, setData } = useVMStore();

  const values: { label: string; el: 'que' | 'lan' | 'fas' }[] = [
    {
      label: 'Wieviele Querschnitte wünschen Sie?',
      el: 'que',
    },
    {
      label: 'Wieviele Längsschnitte wünschen Sie?',
      el: 'lan',
    },
    {
      label: 'Wieviele Fassadenansichten wünschen Sie?',
      el: 'fas',
    },
  ];

  const selectOptions = [
    '3D Modell inkl. gelayoutete 2D Grundrisse, Schnitte und Fassadenansichten',
    'Reines 3D Modell ohne gelayoutete 2D Grundrisse, Schnitte und Fassadenansichten',
  ];

  const _data: IPage10 = data[name];

  function changeType(val: string) {
    if (val !== selectOptions[0]) {
      setData({
        type: val,
      });
    } else {
      setData({
        type: val,
        que: '',
        lan: '',
        fas: '',
      });
    }
  }

  return (
    <Form>
      <FormSelect
        data={selectOptions}
        value={_data.type}
        setValue={changeType}
        placeholder={'Art des Plans'}
        className="pb-3 pb-sm-0"
      />
      <div className="mt-3">
        {typeof _data.que === 'string'
          ? values.map((v) => (
              <Form.Group className="form-grp" key={'page_10_op_' + v.el}>
                {/* <Form.Label htmlFor={v.el}>{v.label}</Form.Label> */}
                <Form.Control
                  placeholder={v.label}
                  id={v.el}
                  type="number"
                  value={_data[v.el]!.replace(/^0+/, '')}
                  onChange={(e) => {
                    setData({ ..._data, [v.el]: e.target.value });
                  }}
                />
              </Form.Group>
            ))
          : ''}
      </div>
    </Form>
  );
}
