import {
  IAccount,
  IPageName,
  ISimpleData,
  IVMStore,
  ProjectFilter,
} from '../interfaces';
import { create } from 'zustand';

export const initData = {
  page1: '',
  // 'page1-1': {
  //   projectName: '',
  //   description: '',
  //   files: [],
  // },
  page2: {
    plz: '',
    ort: '',
    strasse: '',
  },
  page3: '',
  page4: '',
  page5: '',
  page6: '',
  page7: '',
  page8: '',
  page9: {
    dach: 0,
    eg: 1,
    ug: 0,
  },
  page10: {
    type: '',
  },
  page12: '',
  page13: [],
  page14: '',
  page15: {
    vorname: '',
    nachname: '',
    plz: '',
    ort: '',
    email: '',
    telefon: '',
    strasse: '',
    firma: '',
    rechnungsadresse: false,
    r_anschrift: '',
    r_strasse: '',
    r_plz: '',
    r_ort: '',
  },
};

const initSimpleData = {
  files: [],
  description: '',
  project_name: '',
};
type State = {
  curPageIdx: number;
  isValid: boolean;
  data: IVMStore;
  simpleData: ISimpleData;
  detailModel: string;
  completedForm: boolean;
  projectFilter: ProjectFilter;
  setCurPageIdx: (val: number) => void;
  increasePageIdx: () => void;
  decreasePageIdx: () => void;
  setData: (val: any) => void;
  resetData: () => void;
  setSimpleData: (propName: keyof ISimpleData, val: string | File[]) => void;
  showDetailModel: (src: string) => void;
  setCompletedForm: () => void;
  setAccount: (newProp: IAccount | null) => void;
  token: string | null;
  account: IAccount;
  setCredentials: (token: string) => void;
  logout: () => void;
  changeProjectFilter: (type: keyof ProjectFilter) => void;
};

const VStore = create<State>((set, get) => ({
  account: {},
  token: null,
  simpleData: initSimpleData,
  curPageIdx: 1,
  isValid: false,
  data: initData,
  detailModel: '',
  completedForm: false,
  projectFilter: {
    project: true,
    quotation: false,
    invoice: false,
  },

  setCurPageIdx: (val) => {
    set(() => ({
      curPageIdx: val,
    }));
  },
  setCredentials: (token) => set({ token }),
  logout: () => set({ token: null }),
  setAccount: (newProp: IAccount | null) => {
    if (newProp) {
      set({ account: { ...get().account, ...newProp } });
    } else {
      set({ account: {} });
    }
  },
  changeProjectFilter: (type: keyof ProjectFilter) => {
    const newFilterType: ProjectFilter = get().projectFilter;
    if (!newFilterType[type]) {
      for (const key in newFilterType) {
        const newKey = key as keyof ProjectFilter;
        newFilterType[newKey] = newKey === type;
      }
      set(() => ({ projectFilter: { ...newFilterType } }));
    }
  },
  increasePageIdx: () => {
    let _curPageIdx = -1;
    if (
      get().curPageIdx === 1 &&
      get().data.page1 === 'Geben Sie nur Projektinformationen ein'
    ) {
      _curPageIdx = 1.1;
    } else {
      _curPageIdx = get().curPageIdx + 1;
    }
    if (_curPageIdx === 11) {
      _curPageIdx++;
    }
    set(() => ({ curPageIdx: _curPageIdx }));
  },
  decreasePageIdx: () => {
    let _curPageIdx = -1;
    if (get().curPageIdx === 1.1) {
      _curPageIdx = 1;
    } else {
      _curPageIdx = get().curPageIdx - 1;
    }
    if (_curPageIdx === 11) {
      _curPageIdx--;
    }
    set(() => ({ curPageIdx: _curPageIdx }));
  },

  setSimpleData: (propName: keyof ISimpleData, val: string | File[]) => {
    if (propName !== 'files' && typeof val === 'string') {
      set(() => ({ simpleData: { ...get().simpleData, [propName]: val } }));
    } else if (propName === 'files' && Array.isArray(val)) {
      set(() => ({ simpleData: { ...get().simpleData, [propName]: val } }));
    }
  },

  setData: (val) => {
    const _page = ('page' + get().curPageIdx) as IPageName;
    set(() => ({ data: { ...get().data, [_page]: val } }));
    // set(
    //   produce((state: State) => {
    //     state.data[_page] = val;
    //   })
    // );
    if (
      _page === 'page1' ||
      _page === 'page3' ||
      _page === 'page4' ||
      _page === 'page6' ||
      _page === 'page7' ||
      _page === 'page8' ||
      _page === 'page12'
    ) {
      get().increasePageIdx();
    }
    // if (get().curPageIdx != 1.1) {
    // } else {
    //   set(() => ({
    //     data: {
    //       ...get().data,
    //       'page1-1': { ...get().data['page1-1'], ...val },
    //     },
    //   }));
    // }
  },
  resetData: () => {
    set(() => ({ data: initData, simpleData: initSimpleData }));
  },

  showDetailModel: (src) => {
    set(() => ({ detailModel: src }));
  },

  setCompletedForm: () => {
    set(() => ({ completedForm: true }));
  },
}));

export default VStore;
