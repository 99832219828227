import { useState, useEffect } from 'react';
import { QuotationItem, CollapseBtn } from '../index.ts';
import VStore from '../../store';
import { IFormTypes, IProject, IQuotation } from '../../interfaces';
import { getAllQuotation } from '../../services/APIs.ts';
import useAxios from '../../hook/useAxios.ts';
import './_Quotation.scss';
type Props = {
  handleToggleForm: (
    type: IFormTypes | null,
    data: IQuotation | IProject | null
  ) => void;
};
import {Link} from "react-router-dom";

export default function Quotation({ handleToggleForm }: Props) {
  const { projectFilter } = VStore();
  const appAxios = useAxios();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isDraffCollapse, setIsDraffCollapse] = useState<boolean>(false);
  const [isSentCollapse, setIsSentCollapse] = useState<boolean>(false);
  const [draftQuotations, setDraftQuotations] = useState<IQuotation[]>([]);
  const [sentQuotations, setSentQuotations] = useState<IQuotation[]>([]);

  useEffect(() => {
    getAllQuotation(appAxios)
      .then(({ data }) => {
        if (data) {
          const sortedQuotations = [...data];
          sortedQuotations.sort((a, b) => {
            return (
              new Date(b.create_date).getTime() -
              new Date(a.create_date).getTime()
            );
          });
          const tempDraftQuotations: IQuotation[] = [];
          const tempSentQuotations: IQuotation[] = [];
          sortedQuotations.forEach((qtt: IQuotation) => {
            if (qtt.status === 'SENT') {
              tempSentQuotations.push(qtt);
            } else {
              tempDraftQuotations.push(qtt);
            }
          });
          setDraftQuotations(tempDraftQuotations);
          setSentQuotations(tempSentQuotations);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      className={`main-list-container mb-3 ${
        projectFilter.quotation ? '' : 'hide'
      }`}
    >
      <CollapseBtn
        title={'quotation'}
        isCollapsed={isCollapsed}
        setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
      />
      <Link to={'./inquiry-form'} className='new-quotation-btn'>
        <img src="./assets/images/add.svg" alt="New Quotation"/>
        <span>
          New Quotation
        </span>
      </Link>
      <div className={`list-items-container ${isCollapsed ? 'collapse' : ''}`}>

        <div className="child-collapse">
          <div
              className="title-collapse"
              onClick={() => setIsDraffCollapse(!isDraffCollapse)}
          >
            <span className="title">Others</span>
            <span
                className={`toggle-icon-container ${
                    isDraffCollapse ? '' : 'down'
                }`}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </span>
          </div>
          <div className={`collapse-list ${isDraffCollapse ? 'collapse' : ''}`}>
            {draftQuotations.map((quotation) => (
                <QuotationItem
                    handleToggleForm={handleToggleForm}
                    data={quotation}
                    type="quotation"
                    key={quotation.source_document}
                />
            ))}
          </div>
        </div>
        <div
            className="child-collapse"
            onClick={() => setIsSentCollapse(!isSentCollapse)}
        >
          <div className="title-collapse">
            <span className="title">Sent</span>
            <span
                className={`toggle-icon-container ${
                    isSentCollapse ? '' : 'down'
                }`}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </span>
          </div>
          <div className={`collapse-list ${isSentCollapse ? 'collapse' : ''}`}>
            {sentQuotations.map((quotation) => (
                <QuotationItem
                    handleToggleForm={handleToggleForm}
                    data={quotation}
                    type="quotation"
                    key={quotation.source_document}
                />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
