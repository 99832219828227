import { useState } from 'react';
import './_form-select.scss';

export type SelectProps = {
  data: string[];
  value: string;
  setValue: (val: string) => void;
  className?: string;
  size?: 'sm';
  placeholder?: string;
};

export default function FormSelect({
  data,
  value,
  setValue,
  className,
  size,
  placeholder,
}: SelectProps) {
  const [isSelecting, SetSelecting] = useState<boolean>(false);

  return (
    <div
      className={
        'vm-form' +
        (isSelecting ? ' selecting' : '') +
        (className ? ' ' + className : '')
      }
    >
      <div className="position-relative w-100">
        <div className={'vm-form__select ' + (size || '')}>
          <div className={'vm-form-select__container'}>
            <div
              className="vm-form-select__selected"
              onClick={() => SetSelecting(!isSelecting)}
            >
              <span>{value || placeholder}</span>
            </div>
            <div className="vm-form-select__dropdown">
              <ul>
                {data.map((d, idx) => (
                  <li
                    key={'form-select__' + idx}
                    className={value === d ? 'active' : ''}
                    onClick={() => {
                      SetSelecting(false);
                      setValue(d);
                    }}
                  >
                    {d}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="vm-form__backdrop" onClick={() => SetSelecting(false)} />
      <div className="vm-form__filter-bg" />
      <div className="vm-form__place" />
    </div>
  );
}
