import { Outlet } from 'react-router-dom';
import { Header } from '.';
import './Layout.scss';
const Layout = () => {
  return (
    <>
      <Header />
      <div className="main-layout-container">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
