import axios from 'axios';

const BASE_URL =  'https://api.vmts.ch/';
/*const BASE_URL =  'http://192.168.10.57:3500';*/


export default axios.create({
  baseURL: BASE_URL,
});



export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
