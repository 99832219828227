import './_page-5.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page5() {
  const name = 'page5';

  const { data, setData } = useVMStore();

  const values = ['ArchiCAD', 'Revit', 'Vectorworks', 'Allplan'];

  const [soft, ver] = data[name].split('-');

  function isActive(val: string) {
    return soft === val;
  }

  function getLabel(val: string) {
    let label = val;
    if (val === soft && ver) {
      label += '-' + ver;
    }
    return label;
  }

  function getLabelAutocad() {
    return `Einfache DWG/DXF & PDF (Autocad${
      soft === 'Autocad' && ver ? '-' + ver : ''
    })`;
  }

  return (
    <Form>
      <div className="d-flex flex-wrap">
        {values.map((v) => (
          <Form.Group
            className="col-6 col-sm-3 position-relative"
            key={'page_5_' + v}
          >
            <Form.Check
              type="radio"
              id={'page_5_' + v}
              className={'mb-3' + (isActive(v) ? ' active' : '')}
              label={getLabel(v)}
              value={v}
              checked={isActive(v)}
              onChange={() => {
                setData(v);
              }}
            />
            <div style={{ zIndex: 100000 }}>
              <Form.Control
                type="text"
                placeholder="Version"
                maxLength={3}
                value={ver || ''}
                onChange={(e) => setData(soft + '-' + e.target.value)}
              />
            </div>
          </Form.Group>
        ))}
        {data.page4 && data.page4 === '2D' ? (
          <Form.Group className="col-sm-6 col-12">
            <Form.Check
              type="radio"
              id={'page_5_Autocad'}
              className={'mb-3' + (isActive('Autocad') ? ' active' : '')}
              label={getLabelAutocad()}
              value={'Autocad'}
              checked={isActive('Autocad')}
              onChange={() => {
                setData('Autocad');
              }}
            />
            <div style={{ zIndex: 100000 }}>
              <Form.Control
                type="text"
                placeholder="Version"
                maxLength={3}
                value={ver || ''}
                onChange={(e) => setData(soft + '-' + e.target.value)}
              />
            </div>
          </Form.Group>
        ) : (
          ''
        )}
        <div className="col-sm-6 col-12 pe-3 ps-3">
          <Form.Control
            type="text"
            className="mb-0"
            placeholder="Sonstige"
            style={{ padding: '0.5em 2em' }}
            value={!values.includes(soft) && soft !== 'Autocad' ? soft : ''}
            onFocus={() => setData('')}
            onChange={(e) => setData(e.target.value)}
          />
        </div>
      </div>
    </Form>
  );
}
