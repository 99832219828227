import './_page-16.scss';
import useVMStore from '../../../../store';
import FloorOption from '../page-9/floor-option';

export default function Page16() {
  const { data, setCurPageIdx } = useVMStore();

  return (
    <div className="summary w-100 h-100 d-flex flex-md-row flex-column justify-content-center">
      <div>
        <div>
          <p onClick={() => setCurPageIdx(2)}>Angaben zum Objekt</p>
          <span>Adresse</span>
          <span>{data.page2.strasse || '...'}</span>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(3)}>Scanaufnahme</p>
          <span>{data.page3 || '...'}</span>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(4)}>CAD Gebäudemodell</p>
          <span>{data.page4 + ' Modell' || '...'}</span>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(5)}>CAD Software</p>
          <span>{data.page5.replace('-', ' - Ausführung ') || '...'}</span>
        </div>
      </div>
      <div>
        <div>
          <p onClick={() => setCurPageIdx(6)}>Version Masterplan</p>
          <span>{data.page6 || '...'}</span>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(7)}>
            3D Vermessung Zeichnungsdetaillierung
          </p>
          <span>{data.page7 || '...'}</span>
        </div>
        <div onClick={() => setCurPageIdx(8)}>
          <p>Geländedetaillierung</p>
          <span>{data.page8 || '...'}</span>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(10)}>Planlavout</p>
          <span>Art des Plans</span>
          {<span>{data.page10.type}</span>}
          {data.page10.que ? (
            <ul>
              <li>Querschnitte: {data.page10.que}</li>
              <li>Längsschnitte: {data.page10.lan}</li>
              {data.page10.fas ? (
                <li>Fassadenansichten: {data.page10.fas}</li>
              ) : (
                ''
              )}
            </ul>
          ) : (
            ''
          )}
        </div>
        <div>
          <p onClick={() => setCurPageIdx(12)}>Gelände</p>
          <span>Geländeaufnahme</span>
          <span>{data.page12 || '...'}</span>
        </div>
      </div>
      <div>
        <div>
          <p onClick={() => setCurPageIdx(9)}>Geschossauswah</p>
          <div
            className="d-flex flex-column w-100"
            style={{ maxHeight: '500px', overflow: 'auto' }}
          >
            {
              <>
                <FloorOption name="dach" quantity={data.page9.dach} />
                <hr />
                <FloorOption name="eg" quantity={data.page9.eg} />
                <hr />
                <FloorOption name="ug" quantity={data.page9.ug} />
              </>
            }
          </div>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(13)}>Datenupload</p>
          {data.page13.length ? (
            <ul>
              {data.page13.map((f) => (
                <li key={f.name}>{f.name}</li>
              ))}
            </ul>
          ) : (
            '...'
          )}
        </div>
      </div>
      <div>
        <div>
          <p onClick={() => setCurPageIdx(14)}>Schlussbemerkungen</p>
          <span>{data.page14 || '...'}</span>
        </div>
        <div>
          <p onClick={() => setCurPageIdx(15)}>Kontaktangaben</p>
          <div className="d-flex">
            <div className="col">
              <span>Firma</span>
              <span>{data.page15?.firma || '...'}</span>
            </div>
            <div className="col">
              <span>Strasse/Nr.</span>
              <span>{data.page15?.strasse || '...'}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <div className="col">
              <span>Vorname</span>
              <span>{data.page15?.vorname || '...'}</span>
            </div>
            <div className="col">
              <span>Nachname</span>
              <span>{data.page15?.nachname || '...'}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <div className="col">
              <span>PLZ</span>
              <span>{data.page15?.plz || '...'}</span>
            </div>
            <div className="col">
              <span>Ort</span>
              <span>{data.page15?.ort || '...'}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <div className="col">
              <span>Email</span>
              <span>{data.page15?.email || '...'}</span>
            </div>
            <div className="col">
              <span>Telefon</span>
              <span>{data.page15?.telefon || '...'}</span>
            </div>
          </div>
        </div>
        <div>
          <p>Rechnungsadresse</p>
          <div className="d-flex">
            <div className="col">
              <span>Anschrift</span>
              <span>{data.page15?.r_anschrift || '...'}</span>
            </div>
            <div className="col">
              <span>Strasse / Nr.</span>
              <span>{data.page15?.r_strasse || '...'}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <div className="col">
              <span>PLZ</span>
              <span>{data.page15?.r_plz || '...'}</span>
            </div>
            <div className="col">
              <span>Ort</span>
              <span>{data.page15?.r_ort || '...'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
