import { useState, forwardRef, useImperativeHandle } from 'react';
import useVMStore from '../../store';
import html2canvas from 'html2canvas';
import { Modal } from 'react-bootstrap';
import useAxios from '../../hook/useAxios';
import { sendQuotation } from '../../services/APIs';
import { useNavigate } from 'react-router-dom';
// import { saveAs } from 'file-saver';

// interface SendMailProps {}

interface SendMailHandle {
  sendForm: () => void;
}

const SendMail = forwardRef<SendMailHandle>((_, ref) => {
  const {
    data,
    curPageIdx,
    simpleData,
    setCurPageIdx,
    setSimpleData,
    resetData,
  } = useVMStore();
  const [sending, setSending] = useState<boolean>(false);
  const navigate = useNavigate();
  const [sendingState, setSendingState] = useState<
    'sending' | 'success' | 'fail'
  >('sending');

  /*const url = 'http://localhost/album/wp-json/send-email/v1/send';*/
  // const url = 'http://localhost/test-mail/wp-json/send-email/v1/send';
  // const url = window.location.origin + '/wp-json/send-email/v1/send';

  const appAxios = useAxios();
  useImperativeHandle(ref, () => ({
    sendForm() {
      const formData = new FormData();
      // const files = data.page13;
      // if (files.length) {
      //   for (let i = 0; i < files.length; i++) {
      //     formData.append('file[]', files[i]);
      //   }
      // }
      if (curPageIdx !== 1.1) {
        const summary = document.querySelector('.summary');
        if (summary) {
          setSendingState('sending');
          setSending(true);
          return html2canvas(summary as HTMLElement).then(function (canvas) {
            return canvas.toBlob(async (blob) => {
              const fileSummary = new File([blob!], 'quotation-summary.png');
              const clonedData = { ...data };
              if (data.page13?.length) {
                for (let i = 0; i < data.page13.length; i++) {
                  formData.append('files', data.page13[i]);
                }
                clonedData.page13 = '' as any;
              }
              formData.append('files', fileSummary);
              formData.append('data', JSON.stringify(clonedData));

              // console.log('formData', formData.getAll('files'));
              // console.log('formData', formData.getAll('data'));
              // getData();
              // if (blob) {
              //   saveAs(blob, 'quotation-summary.png');
              // }

              try {
                //const response = await sendQuotation(appAxios, formData);
                await sendQuotation(appAxios, formData);
                // console.log(response);
                setSendingState('success');

                setTimeout(() => {
                  navigate('/');
                  setCurPageIdx(1);
                  resetData();
                }, 2000);
              } catch (error) {
                setSendingState('fail');
                setTimeout(() => {
                  navigate('/');
                  setCurPageIdx(1);
                  resetData();
                }, 2000);
              }

              // setSendingState('sending');
              // setSending(true);
              // axios
              //   .post(url, formData)vscode-file://vscode-app/c:/Users/hieu.huynh/AppData/Local/Programs/Microsoft%20VS%20Code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.html
              //   .then(function () {
              //     setSendingState('success');
              //     /*setCompletedForm();*/
              //   })
              //   .catch(function (error) {
              //     console.log(error);
              //     setSendingState('fail');
              //   });
            });
          });
        }
      } else {
        const clonedData = { ...simpleData };
        setSendingState('sending');
        setSending(true);
        if (clonedData.files.length) {
          for (let i = 0; i < clonedData.files.length; i++) {
            formData.append('files', clonedData.files[i]);
          }
          clonedData.files = '' as any;
        }
        if (!clonedData.description) {
          clonedData.description = ' ';
        }
        formData.append('data', JSON.stringify(clonedData));
        sendQuotation(appAxios, formData)
          .then(() => {
            setSendingState('success');
            setTimeout(() => {
              navigate('/');
              setCurPageIdx(1);
              resetData();
            }, 2000);
          })
          .catch(() => {
            setSendingState('fail');
            setTimeout(() => {
              navigate('/');
              setCurPageIdx(1);
              setSimpleData('project_name', '');
              setSimpleData('description', '');
              setSimpleData('files', []);
            }, 2000);
          });
      }

      /*function getPage9Template(name: string, isNull = false) {
        return `
            <table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; padding: 5px;'>
                <tbody>
                <tr>
                    <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                        <table
                                style='
                                        min-width: 100%;
                                        max-width: 100%;
                                        word-wrap: break-word;
                                        word-break: break-word;
                                        padding: 10px 28px;
                                        border-radius: 9999px;
                                        border-width: 1px;
                                        border-style: solid;
                                        ${
                                          isNull
                                            ? 'opacity: 0.3'
                                            : 'border-color: green; color: green'
                                        }
                                    '
                        >
                            <tbody>
                            <tr>
                                <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; text-align: center'>
                                    ${name.replace('DACH', 'Dachgeschoss')}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            `;
      }*/

      /*function getBillingTemplate() {
        const { rechnungsadresse, r_anschrift, r_strasse, r_ort, r_plz } =
          data.page15;
        return rechnungsadresse
          ? `<table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; padding: 20px; border-top: 1px solid #000;'>
                                <tbody>
                                <tr>
                                    <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                                        <table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; font-size: 16px; font-weight: 600;'>
                                            <tbody>
                                            <tr>
                                                <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                                                    Rechnungsadresse
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; margin-top: 12px;'>
                                            <tbody>
                                            <tr>
                                                <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                                                    <b>Anschrift</b><br/>
                                                    ${r_anschrift || '...'}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; margin-top: 12px;'>
                                            <tbody>
                                            <tr>
                                                <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                                                    <b>Adresse</b><br/>
                                                    ${r_strasse || '...'}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; margin-top: 12px;'>
                                            <tbody>
                                            <tr>
                                                <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                                                    <b>PLZ</b><br/>
                                                    ${r_plz}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word; margin-top: 12px;'>
                                            <tbody>
                                            <tr>
                                                <td style='min-width: 100%; max-width: 100%; word-wrap: break-word; word-break: break-word;'>
                                                    <b>Ort</b><br/>
                                                    ${r_ort}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>`
          : '';
      }*/

      /*function createFloorTemplate(type: 'dach' | 'eg' | 'ug'): string {
        const num = data.page9[type];
        const template = [
          getPage9Template(
            (type !== 'eg' ? '1. ' : '') + type.toUpperCase(),
            num === 0
          ),
        ];
        if (num > 1) {
          if (type !== 'eg') {
            for (let i = 2; i < num + 1; i++) {
              template.push(getPage9Template(i + '. ' + type.toUpperCase()));
            }
          } else {
            for (let i = 1; i < num; i++) {
              template.push(getPage9Template(i + '. OG'));
            }
          }
        }
        if (type === 'eg') {
          template.reverse();
        }
        return template.join('');
      }*/

      /*function getData() {
        for (const [page, val] of Object.entries(data)) {
          switch (page) {
            case 'page2': {
              formData.append('address', val.strasse);
              break;
            }
            case 'page3': {
              formData.append('scope', val);
              break;
            }
            case 'page4': {
              formData.append('cad_type', val);
              break;
            }
            case 'page5': {
              formData.append('cad_soft', val);
              break;
            }
            case 'page6': {
              formData.append('masterplan', val);
              break;
            }
            case 'page7': {
              formData.append('standard', val);
              break;
            }
            case 'page8': {
              formData.append('outside_standard', val);
              break;
            }
            case 'page9': {
              formData.append('floor_dach', createFloorTemplate('dach'));
              formData.append('floor_eg', createFloorTemplate('eg'));
              formData.append('floor_ug', createFloorTemplate('ug'));
              break;
            }
            case 'page10': {
              formData.append(
                'plan_type',
                val.type +
                  (typeof val.que !== 'string'
                    ? ''
                    : `<ul><li>Querschnitte: ${val.que}</li><li>Längsschnitte: ${val.lan}</li>` +
                      (val.fas
                        ? `<li>Fassadenansichten: ${val.fas}</li></ul>`
                        : '</ul>'))
              );
              break;
            }
            case 'page12': {
              formData.append('terrain', val);
              break;
            }
            case 'page13': {
              let temp = val.files.length
                ? val.files.map((f: File) => `<li>${f.name}</li>`).join('')
                : '...';
              if (temp) {
                temp = `<ol>${temp}</ol>`;
              }
              formData.append('file_names', temp);
              break;
            }
            case 'page14': {
              formData.append('note', val);
              break;
            }
            case 'page15': {
              formData.append('vorname', val.vorname);
              formData.append('nachname', val.nachname);
              formData.append('plz', val.plz);
              formData.append('ort', val.ort);
              formData.append('email', val.email);
              formData.append('telefon', val.telefon);
              formData.append('firma', val.firma || '...');
              formData.append('strasse', val.strasse || '...');
              formData.append('billing', getBillingTemplate());
              break;
            }
          }
        }
      }*/
    },
  }));

  return (
    <div className="send-mail">
      <Modal
        show={sending}
        // onHide={() => {
        //   if (sendingState !== 'sending') {
        //     setSending(false);
        //   }
        // }}
        centered={true}
      >
        <Modal.Header
        // closeButton={sendingState !== 'sending'}
        />
        <Modal.Body>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img
              src={`./assets/images/${sendingState}.svg?${Date.now()}`}
              alt="Detail"
              className="w-50"
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default SendMail;
