import { axiosPrivate } from '../api/axios';
import { useEffect } from 'react';
import VStore from '../store';
import useRefreshToken from './useRefreshToken.ts';

const useAxios = () => {
  const { token } = VStore();
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      async (configs) => {
        if (!configs.headers['Authorization']) {
          const _token = token || (await (await refresh)());
          // if (token) {
          //   console.log(jwtDecode(token));
          // }
          configs.headers['Authorization'] = `Bearer ${_token}`;
        }
        return configs;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error.config;
        if (error?.response?.status === 401 && !prevRequest.sent) {
          prevRequest.sent = true;
          const newAccessToken = await (await refresh)();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        } else {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [token, refresh]);

  return axiosPrivate;
};

export default useAxios;
