import './_page-12.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page12() {
  const name = 'page12';

  const { data, setData } = useVMStore();

  const values = [
    'Terrain entlang der Fassade, 1.00m',
    'Eigens festgelegter Bereich',
  ];

  return (
    <Form className="d-flex flex-column flex-md-row">
      {values.map((v, idx) => (
        <Form.Check
          type="radio"
          key={'page_12_op_' + idx}
          id={'page_12_op_' + idx}
          className="mb-3 col"
          label={v}
          value={v}
          checked={data[name] === v}
          onChange={(e) => {
            setData(e.target.value);
          }}
        />
      ))}
    </Form>
  );
}
