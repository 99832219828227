import './_modal-container.scss';

type ModalContainerProp = {
  children: React.ReactNode;
};

const ModalContainer = ({ children }: ModalContainerProp) => {
  return <>{children}</>;
};

export default ModalContainer;
