import './floor-option.scss';

export type Page9SelectionProps = {
  name: 'dach' | 'eg' | 'ug';
  quantity: number;
  increase?: () => void;
  decrease?: () => void;
};

export default function FloorOption({
  name,
  quantity,
  increase,
  decrease,
}: Page9SelectionProps) {
  function getName(idx: number) {
    if (name === 'ug') {
      return `${idx + 1}. UG`;
    } else if (name === 'eg') {
      return idx !== 0 ? `${idx}. OG` : 'EG';
    } else {
      return `${idx + 1}. Dachgeschoss`;
    }
  }

  return (
    <div className="floor-options d-flex">
      {increase ? (
        <div
          className={
            'col-3 col-md-2 d-flex ' +
            (name !== 'ug' ? 'align-items-end' : 'align-items-start')
          }
        >
          <div className="q-btn" onClick={decrease}>
            -
          </div>
          <div className="q-btn" onClick={increase}>
            +
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={increase ? 'col-9' : 'col-12'}>
        <ul
          className={
            'd-flex justify-content-around ' +
            (name === 'ug' ? 'flex-wrap' : 'flex-wrap-reverse')
          }
        >
          {quantity > 0 ? (
            Array.from(Array(quantity).keys()).map((q) => (
              <li
                key={name + '_' + q}
                style={{ marginBottom: name === 'ug' && q === 0 ? '1em' : '' }}
                className={quantity > 4 ? 'col-6' : 'col-12'}
              >
                <span>{getName(q)}</span>
              </li>
            ))
          ) : (
            <li className="q-0">
              <span>
                {name === 'ug'
                  ? '1. UG'
                  : name === 'eg'
                  ? 'EG'
                  : 'Dachgeschoss'}
              </span>
            </li>
          )}
        </ul>
      </div>
      {increase ? <div className="col-0 col-md-2"></div> : ''}
    </div>
  );
}
