import { FormEvent, useEffect, useRef, useState } from 'react';
import './_login.scss';
import axios from '../../api/axios';
import VStore from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuthContext } from '../../hook/useAuthContext';

const LOGIN_URL = '/auth';

export default function Login() {
  const { setCredentials } = VStore();
  const navigate = useNavigate();
  const errorRef = useRef(null);
  const { updateUser } = useAuthContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        const accessToken = response?.data?.accessToken;
        const imgUrl = response?.data?.img_url;
        setCredentials(accessToken);
        if (imgUrl) {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 100);
          const expirationDateString = expirationDate.toUTCString();
          updateUser({ imageUrl: imgUrl });
          // setAccount({ imageUrl: imgUrl });
          document.cookie = `img_url=${encodeURIComponent(
            imgUrl
          )}; expires=${expirationDateString}; path=/`;
        }
        navigate(from, { replace: true });
      }
      setUsername('');
      setPassword('');
    } catch (err: any) {
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response.status === 401) {
        setErrorMsg('Unauthorized');
      } else {
        setErrorMsg('Login Failed');
      }
    }
  }

  const ErrorMsg = errorMsg ? (
    <p
      ref={errorRef}
      className={errorMsg ? 'err-msg' : 'offscreen'}
      aria-live="assertive"
    >
      {errorMsg}
    </p>
  ) : null;

  useEffect(() => {
    setErrorMsg('');
  }, [username, password]);

  return (
    <section className="login">
      <div className="logo-container-main">
        <div className="logo-subcontainer">
          <img
            className="image-logo"
            src="/assets/images/logo.png"
            alt="logo"
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="control-form">
          <label htmlFor="user">Username</label>
          <div className="input-container">
            <Input
              type="text"
              placeHolder="Username"
              id="user"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoComplete={'off'}
            ></Input>
          </div>
        </div>
        <div className="control-form">
          <label htmlFor="password">Password</label>
          <div className="input-container">
            <Input
              type="password"
              placeHolder="Password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete={'on'}
            ></Input>
          </div>
        </div>
        {ErrorMsg}
        <div className="control-form">
          <Button
            width="100%"
            border="none"
            borderRadius="20px"
            fontWeight="500"
            type="submit"
            padding="6px 0"
            margin="12px 0 0 0"
          >
            <div>Login</div>
          </Button>
        </div>
      </form>
    </section>
  );
}
