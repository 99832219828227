import { ChangeEvent } from 'react';
import './_page-13.scss';
import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page13() {
  const name = 'page13';

  const { data, setData } = useVMStore();

  const _data = data[name];

  const limitSize = 20 * 1024 * 1024;

  const handleUploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileList: File[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        fileList.push(event.target.files[i]);
      }
      // const files = event.target.files;
      if (fileList) {
        let sumSize = 0;
        const length = fileList.length;
        if (length) {
          for (let i = 0; i < length; i++) {
            sumSize += fileList[i].size;
          }
          if (sumSize > limitSize) {
            alert('Files size must be less than 20MB');
            return;
          } else {
            fileList && setData(fileList);
          }
        }
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Form>
        <div className="vm-input-file justify-content-between align-items-center">
          <span>Dateien hierher ziehen (Max. Dateigrösse: 20 MB)</span>
          <div
            className="highlighted-btn vm-rounded text-center"
            style={{ padding: '0.75em' }}
          >
            <span>Dateien wählen</span>
          </div>
          <input type="file" multiple={true} onChange={handleUploadFiles} />
        </div>
      </Form>
      <p className="mt-3 mb-3 text-center">
        Es können keine Dateien hochgeladen werden, die größer als 20 MB sind
        {/* Sollten Sie mehr als 20 MB hochladen wollen, können Sie dies gerne unter{' '}
        <a href="https://vermessung-digital.wetransfer.com/" target="_blank">
          www.vermessungdigital.wetransfer.com
        </a>{' '}
        tun */}
      </p>
      {_data.length ? (
        <div className="vm-input-file mt-3">
          <ol>
            {_data.map((f) => (
              <li key={f.name}>{f.name}</li>
            ))}
          </ol>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
