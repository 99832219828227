import { AxiosInstance } from 'axios';

export async function fetchAllProjects(useAxios: AxiosInstance) {
  return await useAxios.get('/partner-project?ref=1158');
}

export async function addProjectComment(
  useAxios: AxiosInstance,
  data: FormData
) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Accept': '*/*',
      // 'Accept-Encoding': 'gzip, deflate, br',
      // 'Connection': 'keep-alive',
    },
  };
  return await useAxios.post('/send-comment', data, config);
}

export async function getAllProjects(useAxios: AxiosInstance) {
  return await useAxios.get('/partner-project');
}

export async function getAllQuotation(useAxios: AxiosInstance) {
  return await useAxios.get('/get-quotation');
}

export async function getAllInvoice(useAxios: AxiosInstance) {
  return await useAxios.get('/get-invoice');
}

export async function getProjectComments(
  useAxios: AxiosInstance,
  projectId: string
) {
  return await useAxios.get(`/get-comments/${projectId}`);
}

export async function getLogout(useAxios: AxiosInstance) {
  return await useAxios.get('/auth/logout', { withCredentials: true });
}

export async function sendQuotation(
  useAxios: AxiosInstance,
  formData: FormData
) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return await useAxios.post('/send-quotation', formData, config);
}
