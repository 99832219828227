import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';
import './_page-2.scss';

export default function Page2() {
  const name = 'page2';

  const { data, setData } = useVMStore();

  const _data = data[name];

  function changeData(val: string | number, name: string) {
    const newData = { ..._data, [name]: val };
    setData(newData);
  }

  return (
    <Form>
      <Form.Group>
        <Form.Control
          placeholder={'Strasse / Nr.'}
          value={_data.strasse}
          onChange={(e) => changeData(e.target.value, 'strasse')}
        />
      </Form.Group>
      <div className="d-flex">
        <Form.Group className="col me-3">
          <Form.Control
            type="number"
            placeholder={'PLZ*'}
            value={_data.plz.replace(/^0+/, '')}
            onChange={(e) => changeData(e.target.value, 'plz')}
          />
        </Form.Group>
        <Form.Group className="col">
          <Form.Control
            placeholder={'Ort*'}
            value={_data.ort}
            onChange={(e) => changeData(e.target.value, 'ort')}
          />
        </Form.Group>
      </div>
    </Form>
  );
}
