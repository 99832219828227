import './Button.scss';
import { Link } from 'react-router-dom';
type ButtonProps = {
  type?: 'submit' | 'button' | 'reset';
  padding?: string;
  width?: string;
  margin?: string;
  fontWeight?: string;
  id?: string;
  className?: string;
  borderRadius?: string;
  border?: string;
  variant?:
    | 'btn-red-secondary'
    | 'btn-red-primary'
    | 'btn-dark-secondary'
    | 'btn-gray-primary';
  children?: React.ReactNode;
  onClick?: () => void;
  linkTo?: string;
};
const Button = ({
  type = 'submit',
  id,
  className,
  padding,
  width,
  fontWeight,
  margin,
  variant = 'btn-dark-secondary',
  children,
  borderRadius,
  border,
  onClick = () => {
    console.log();
  },
  linkTo,
}: ButtonProps) => {
  return linkTo ? (
    <Link
      to={linkTo}
      onClick={onClick}
      style={{ padding, width, margin, fontWeight, borderRadius, border }}
      type={type as 'submit' | 'button' | 'reset'}
      id={id}
      className={`${'button-prm ' + variant + (className ? className : '')}`}
    >
      {children}
    </Link>
  ) : (
    <button
      onClick={onClick}
      style={{ padding, width, margin, fontWeight, borderRadius, border }}
      type={type as 'submit' | 'button' | 'reset'}
      id={id}
      className={`${'button-prm ' + variant + (className ? className : '')}`}
    >
      {children}
    </button>
  );
};

export default Button;
