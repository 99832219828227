import { newsPosted } from '../../_data.ts';
import { dateToCustomString } from '../../services/utils.ts';
import './_news-detail.scss';
function NewsDetail() {
  return (
    <div className="news-detail">
      <div className="news-detail-main">
        <div className="img-new-container">
          <div className="image-new-main">
            <div className="news-title-container">
              <h4 className="news-title">{newsPosted.name}</h4>
              <span className="posted-date">
                {dateToCustomString(newsPosted.postedDate)}
              </span>
            </div>
            <img src={newsPosted.imgUrl} alt="news" />
          </div>
        </div>
        <div className="news-detail-main">
          {/* <h4>{newsPosted.name}</h4> */}
          <div className="content-container">
            <div className="content-main">
              <p>
                {newsPosted.content} Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Corporis repellat, labore quisquam illo beatae
                necessitatibus mollitia ab perferendis dolore consequuntur nam
                assumenda quis non culpa excepturi, pariatur eveniet, soluta
                omnis!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
