import {dateToPopularString} from "../../services/utils.ts";
import {IInvoice} from "../../interfaces";


export type InvoiceItemProps = {
    data: IInvoice;
}

export default function InvoiceItem({data}: InvoiceItemProps) {

    const {state, name, create_date, amount_total} = data;

    const handleOpenForm = () => {
        //openForm(dataValue);
    };

    return (
        <div onClick={handleOpenForm} className="project-item">
            <div className="project-item-container">
                <div className="item-status-container">
                    <div className="item-status-main">
                        <div className="item-status-value">{state.toUpperCase()}</div>
                    </div>
                </div>
                <div className="project-item__info">
            <span>
              {name}
            </span>
                    <div className='d-flex justify-content-between w-100'>
                        <span>
              Created Date: &nbsp;
                            {dateToPopularString(new Date(create_date))}
            </span>
                        <span>
                Total: &nbsp; <b>{amount_total}</b>
                    </span>
                    </div>

                </div>
            </div>
        </div>
    );
}
