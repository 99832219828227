import { IComment } from '../../../interfaces';
import Parser from 'html-react-parser';
import './_comment-item.scss';

interface CommentItemProps {
  cmt: IComment;
  handleDownloadFile: (fileUrl: string) => void;
  handleRenderFile: (fileUrl: string) => React.ReactNode;
}
const CommentItem = ({
  cmt,
  handleDownloadFile,
  handleRenderFile,
}: CommentItemProps) => {
  return (
    <div key={cmt._id} className="comment-item">
      <div className="comment-user-container">
        <div className="comment-user-avatar">
          <i className="fa-solid fa-user"></i>
        </div>
      </div>
      <div className="comment-main-content">
        <div className="comment-item-content">
          <div className="option-button">
            <i className="fa-solid fa-ellipsis-vertical"></i>
            <div className="comment-option-container">
              {/* <div className="comment-option-item">Delete</div> */}
              {/* <div className="comment-option-item">Edit</div> */}
            </div>
          </div>
          {Parser(cmt.content)}
        </div>
        {cmt.files.length > 0 && (
          <>
            <div className="comment-title">
              <div>Files:</div>
            </div>
            <div className="comment-item-files">
              {cmt.files.map((fileUrl) => {
                return (
                  <div
                    key={fileUrl}
                    onClick={() => handleDownloadFile(fileUrl)}
                    className="file-commented-container"
                  >
                    <div className="file-name">{fileUrl.split('\\').pop()}</div>
                    {handleRenderFile(fileUrl)}
                    {/* <img src={fileUrl} alt="" /> */}
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="additional-infor-container">
          <div className="additional-infor created-date">
            <div className="infor-title">Created</div>
            <div className="infor-value">
              {
                new Date(cmt.create_date).toLocaleString('en-GB')
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
