import { IAddedComment } from './interfaces';

export const detailSample = {
  id: '1',
  name: '4139-VD-PR23.023 Korbmacher Stift. Köln',
  deadline: '2021-01-01',
  progress: 50,
  comments: [
    {
      content:
        '<ol><li>qwdqwdqw wfe ewf fw few  wff f we fwe<u> wef wef wefwefwe</u><em><u> wef wef wef we</u></em></li><li><em><u>f wef</u></em></li></ol><p><em><u>few</u></em></p>',
      create_date: new Date('2021-01-01'),
      update_date: new Date('2021-01-01'),
      create_id: 'John.Doe.1',
      files: ['https://picsum.photos/200/300', 'https://picsum.photos/200/300'],
    },
    {
      content:
        '<p>&nbsp;&nbsp;•&nbsp;<strong>Project</strong>: Developer Tasks</br>&nbsp;&nbsp;•&nbsp;<strong>Kanban State</strong>: Normal</br>&nbsp;&nbsp;•&nbsp;<strong>Assigned to</strong>: Hoai.Nguyen</p>',
      create_date: new Date('2021-01-01'),
      update_date: new Date('2021-01-01'),
      create_id: 'John.Doe.2',
      files: ['https://picsum.photos/200/300', 'https://picsum.photos/200/300'],
    },
    {
      content: '<p><strong>Task created</strong></p>',
      create_date: new Date('2021-01-01'),
      update_date: new Date('2021-01-01'),
      create_id: 'John.Doe.3',
      files: ['https://picsum.photos/200/300'],
    },
    {
      content: '<p><strong>Task created</strong></p>',
      create_date: new Date('2021-01-01'),
      update_date: new Date('2021-01-01'),
      create_id: 'John.Doe.4',
      files: ['https://picsum.photos/200/300'],
    },
    {
      content: '<p><strong>Task created</strong></p>',
      create_date: new Date('2021-01-01'),
      update_date: new Date('2021-01-01'),
      create_id: 'John.Doe.5',
      files: ['https://picsum.photos/200/300'],
    },
  ],
};

export const commentSample: IAddedComment = {
  projectId: 1,
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  files: [new File([], '')],
};

export const newsPosted = {
  id: 1,
  name: '4139-VD-PR23.023 Korbmacher Stift. Köln',
  postedDate: new Date('2021-01-01'),
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis repellat, labore quisquam illo beatae necessitatibus mollitia ab perferendis dolore consequuntur nam assumenda quis non culpa excepturi, pariatur eveniet, soluta omnis!',
  imgUrl: 'https://picsum.photos/200/300',
};

export const quotationSample = [
  {
    _id: '64c38907825686432af40a4e',
    create_date: '2023-07-28T09:23:19.121Z',
    update_date: '2023-07-28T09:23:19.121Z',
    customer_ref: '1158',
    source_document: '', //will be added after VMT adding
    data: {
      page1: 'Geben Sie vollständige Informationen ein',
      page2: {
        plz: '1',
        ort: '1',
        strasse: '1',
      },
      page3: 'Aufnahme und Auswertung Innen und außen',
      page4: '3D',
      page5: 'Revit-1',
      page6: '3D Vermessung Digital Standardvorlage',
      page7: 'Hoch',
      page8: 'Standard',
      page9: {
        dach: 0,
        eg: 1,
        ug: 0,
      },
      page10: {
        type: 'Reines 3D Modell ohne gelayoutete 2D Grundrisse, Schnitte und Fassadenansichten',
      },
      page12: 'Eigens festgelegter Bereich',
      page13: [],
      page14: '1',
      page15: {
        vorname: '1',
        nachname: '1',
        plz: 1,
        ort: '1',
        email: '1@few.fe',
        telefon: '1',
        strasse: '1',
        firma: '1',
        rechnungsadresse: false,
        r_anschrift: '',
        r_strasse: '',
        r_plz: '',
        r_ort: '',
      },
    },
    status: 'initating',
  },
  {
    _id: '64c383958910967ad37d537c',
    create_date: '2023-07-28T09:00:05.467Z',
    update_date: '2023-07-28T09:00:05.467Z',
    customer_ref: '1158',
    source_document: '', //will be added after after VMT adding
    data: {
      files: [],
      description: 'Test',
      project_name: 'Test',
    },
    status: 'initating',
  },
  {
    _id: '64c383958910967ad37d537c',
    create_date: '2023-07-28T09:00:05.467Z',
    update_date: '2023-07-28T09:00:05.467Z',
    customer_ref: '1158',
    source_document: 'e91a31df-3bed-45d7-a256-5692c9b716ad', //added after VMT adding
    data: {
      files: [],
      description:
        '4586-Audimax Rettungswege is the main project of the ILAT university.',
      project_name: '4586-Audimax Rettungswege',
    },
    status: 'draft',
  },
  {
    _id: '64c38907825686432af40a4e',
    create_date: '2023-07-28T09:23:19.121Z',
    update_date: '2023-07-28T09:23:19.121Z',
    customer_ref: '1158',
    source_document: '', //will be added after VMT adding
    data: {
      page1: 'Geben Sie vollständige Informationen ein',
      page2: {
        plz: '1',
        ort: '1',
        strasse: '1',
      },
      page3: 'Aufnahme und Auswertung Innen und außen',
      page4: '3D',
      page5: 'Revit-1',
      page6: '3D Vermessung Digital Standardvorlage',
      page7: 'Hoch',
      page8: 'Standard',
      page9: {
        dach: 0,
        eg: 1,
        ug: 0,
      },
      page10: {
        type: 'Reines 3D Modell ohne gelayoutete 2D Grundrisse, Schnitte und Fassadenansichten',
      },
      page12: 'Eigens festgelegter Bereich',
      page13: [],
      page14: '1',
      page15: {
        vorname: '1',
        nachname: '1',
        plz: 1,
        ort: '1',
        email: '1@few.fe',
        telefon: '1',
        strasse: '1',
        firma: '1',
        rechnungsadresse: false,
        r_anschrift: '',
        r_strasse: '',
        r_plz: '',
        r_ort: '',
      },
    },
    status: 'sent',
  },
];

export const projectSample = [
  {
    id: '4263',
    name: '4506-LVHS Freckenhorst',
    deadline: '2023-09-13T00:00:00.000Z',
    processed_percent: 95.45454545454545,
    status: 'sale to invoice',
  },
  {
    id: '4274',
    name: '4517-VD-PR23.056 Varwick Laer',
    deadline: '2023-11-05T00:00:00.000Z',
    processed_percent: 0,
    status: 'sale to invoice',
  },
  {
    id: '4276',
    name: '4519-4044-3895 A2 Forum Flugpunkte Teil 2',
    deadline: '2023-09-18T00:00:00.000Z',
    processed_percent: 0,
    status: 'sale to invoice',
  },
  {
    id: '4279',
    name: '4522-E57 to pointcab',
    deadline: '2023-09-27T00:00:00.000Z',
    processed_percent: 0,
    status: 'sale to invoice',
  },
  {
    id: '4308',
    name: '4552- Preuss Krefeld',
    deadline: '2023-09-15T00:00:00.000Z',
    processed_percent: 94.73684210526316,
    status: 'sale to invoice',
  },
  {
    id: '4382',
    name: '4626-VD-PR23.067 Zeit für Brot Düsseldorf',
    deadline: '2023-10-05T00:00:00.000Z',
    processed_percent: 0,
    status: 'sale to invoice',
  },
];

// export const commentSamples = [
//   {
//     id: 1,
//     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
//     created: '2021-01-01',
//     updated: '2021-01-01',
//     createdBy: 'John Doe',
//     image: 'https://picsum.photos/200/300',
//     data: [],
//   },
// ];
