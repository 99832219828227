import { createContext, useContext, useEffect, useState } from 'react';
import { IUser } from '../interfaces';
import VStore from '../store';
import jwtDecode from 'jwt-decode';
// import axios from '../api/axios';
// import VStore from '../store';
// import jwtDecode from 'jwt-decode';

interface IContextValue {
  user: IUser | null;
  updateUser: (user: IUser | null) => void;
}

const UserContext = createContext<IContextValue | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { token } = VStore();
  // const refresh = UseRefreshToken();
  const [user, setUser] = useState<IUser | null>(
    null
    // {
    // username: 'hoai',
    // ref: '123',
    // }
  );

  const updateUser = (newInfor: IUser | null) => {
    setUser(newInfor);
  };

  useEffect(() => {
    let recievedInfor: IUser | null = null;

    if (token) {
      recievedInfor = jwtDecode(token);
      // if (recievedInfor) {
      //   setUser({ ...recievedInfor, role: 1 } as IUser);
      // }
    }

    const imageUrl = decodeURIComponent(
      document.cookie.replace(
        /(?:(?:^|.*;\s*)img_url\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      )
    );

    setUser(
      imageUrl
        ? { ...recievedInfor, imageUrl: imageUrl, role: 1 }
        : { ...recievedInfor, role: 1 }
    );
    // console.log({ ...recievedInfor, imageUrl: imageUrl });
  }, [token]);

  // useEffect(() => {
  //   if (!token) {
  //     getNewToken();
  //   }
  // }, [token]);

  // useEffect(() => {
  //   const checkToken = async () => {
  //     const config = {};
  //     if (token) {
  //       const _token = await (await refresh)();
  //     }
  //   };
  //   checkToken();
  // }, [token]);

  // const getNewToken = async () => {
  //   const response = await axios.get('/auth/refresh-token', {
  //     withCredentials: true,
  //     // headers: {
  //     //   Authorization: `Bearer ${token}`,
  //     // },
  //   });
  //   if (response.status !== 200 && !response.data.accessToken) {
  //     setUser(null);
  //     throw new Error('Error refreshing token');
  //   } else {
  //     setCredentials(response.data.accessToken);
  //     setUser(jwtDecode(response.data.accessToken));
  //   }
  //   return response.data.accessToken;
  // };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useMyContext must be used within a AuthProvider');
  }
  return context;
};
