import './_home.scss';

import ProjectDetailModal from '../../components/Modal/Project/ProjectDetailModal';
import QuotationDetailModal from '../../components/Modal/Quotation/QuotationDetailModal/index.tsx';
import {
  Quotation,
  Project,
  Invoice,
  TypeFilter,
  Button,
} from '../../components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  IFormTypes,
  IOpeningForm,
  IProject,
  IQuotation,
} from '../../interfaces/index.ts';

export default function Home() {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
  };
  const [openingForm, _] = useState<IOpeningForm>({
    type: null,
    data: null,
  });
  const handleToggleForm = (
    type: IFormTypes | null,
    data: IQuotation | IProject | null
  ) => {
    console.log('handleToggleForm', type, data);
    //setOpeningForm({ type, data });
  };
  return (
    <div className="home">
      {openingForm.type === 'quotation' && (
        <QuotationDetailModal
          isShow={true}
          handleClose={() => handleToggleForm(null, null)}
          data={openingForm.data as IQuotation}
        />
      )}
      {openingForm.type === 'project' && (
        <ProjectDetailModal
          isShow={true}
          handleClose={() => handleToggleForm(null, null)}
          data={openingForm.data as IProject}
        />
      )}
      <div className="home-container">
        <TypeFilter />
        <Quotation handleToggleForm={handleToggleForm} />
        <Project handleToggleForm={handleToggleForm} />
        <Invoice />
        {false && ( // TODO: remove this line
          <Button
            onClick={() => handleNavigate('/add-request')}
            className="btn btn-dark m-2 btn-sm adding-quotation-btn shadow"
          >
            <span className="d-flex justify-content-center">
              <i className="fa-solid fa-plus fa-lg"></i>
            </span>
          </Button>
        )}
      </div>
    </div>
  );
}
