import ReactDOM from 'react-dom/client';
import { Routes, Route, HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './assets/styles/index.scss';
import { SkeletonTheme } from 'react-loading-skeleton';
import { AuthProvider } from './hook/useAuthContext';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <BrowserRouter>
  <HashRouter>
    <SkeletonTheme baseColor="var(--gray-dark)" highlightColor="#595959">
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </SkeletonTheme>
  </HashRouter>
  // </BrowserRouter>
);
