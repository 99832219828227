import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';
import './_page-1.scss';

export default function Page1() {
  const name = 'page1';

  const { data, setData } = useVMStore();

  const values = [
    'Geben Sie nur Projektinformationen ein',
    'Geben Sie vollständige Informationen ein',
  ];

  return (
    <Form className="d-flex flex-wrap justify-content-around">
      {values.map((v, idx) => (
        <Form.Check
          type="radio"
          key={'r' + idx}
          id={'r' + idx}
          className="mb-3 col-12 col-md-6 p-0 p-md-1" // d-flex justify-content-center
          label={v}
          value={v}
          checked={data[name] === v}
          onChange={(e) => {
            setData(e.target.value);
          }}
        />
      ))}
    </Form>
  );
}
