import { useEffect, useState } from 'react';
import { CollapseBtn, ProjectItem } from '../index.ts';
import VStore from '../../store';
import { IFormTypes, IProject, IQuotation } from '../../interfaces';
import { getAllProjects } from '../../services/APIs.ts';
import useAxios from '../../hook/useAxios.ts';
import './_project.scss';
type Props = {
  handleToggleForm: (
    type: IFormTypes | null,
    data: IQuotation | IProject | null
  ) => void;
};
export default function Project({ handleToggleForm }: Props) {
  const { projectFilter } = VStore();

  const appAxios = useAxios();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    getAllProjects(appAxios)
      .then(({ data }) => {
        if (data) {
          console.log(data);
          const currentDate = new Date().getTime();
          const previousProjects: any[] = [];
          const sortedInvoices = [...data].filter((project) => {
            if (
              !project.deadline ||
              new Date(project.deadline).getTime() <= currentDate
            ) {
              previousProjects.push(project);
              return false;
            }
            return true;
          });
          previousProjects.sort((a, b) => {
            if (!a.deadline) {
              return 1;
            } else if (!b.deadline) {
              return -1;
            }
            return (
              new Date(b.create_date).getTime() -
              new Date(a.create_date).getTime()
            );
          });

          sortedInvoices.sort((a, b) => {
            if (!a.deadline) {
              return 1;
            } else if (!b.deadline) {
              return -1;
            }
            const timeDiffA = Math.abs(
              new Date(a.deadline).getTime() - currentDate
            );
            const timeDiffB = Math.abs(
              new Date(b.deadline).getTime() - currentDate
            );
            return timeDiffA - timeDiffB;
          });
          setProjects([...previousProjects, ...sortedInvoices]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      className={`main-list-container mb-3 ${
        projectFilter.project ? '' : 'hide'
      }`}
    >
      <CollapseBtn
        title={'project'}
        isCollapsed={isCollapsed}
        setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
      />
      <div className={`list-items-container ${isCollapsed ? 'collapse' : ''}`}>
        {projects.map((projects) => (
          <ProjectItem
            handleToggleForm={handleToggleForm}
            data={projects}
            key={projects.id}
          />
        ))}
      </div>
    </div>
  );
}
