import ReactQuill from 'react-quill';
import './AddNews.scss';
import { useEffect, useState } from 'react';
import { Button } from '../../components';
import { useLocation } from 'react-router-dom';

type formState = {
  title: string;
};

const AddNews = () => {
  const [content, setContent] = useState<string>('');
  const [formValues, setFormValues] = useState<formState>({
    title: '',
  });
  const [isAdnew, setIsAdnew] = useState<boolean>(true);
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    if (location.pathname.includes('edit-news')) {
      setIsAdnew(false);
    }
  }, []);
  const handleAddNews = () => {
    // console.log(content);
    // console.log(formValues);
  };

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof formState
  ) => {
    setFormValues({ ...formValues, [key]: e.target.value });
  };

  // const onSubmitComment = async () => {
  //   const quillEditor = document.querySelector('.quill .ql-editor');
  //   let isBlank = false;
  //   if (quillEditor) {
  //     //  cheking if the editor is empty
  //     const regex = /^<p>(\s*|<br>)<\/p>$/;
  //     isBlank = quillEditor?.innerHTML
  //       ? regex.test(quillEditor?.innerHTML)
  //       : true;
  //   }
  //   if (!isBlank) {
  //     //  if the editor is not empty or there is at least one file
  //     try {
  //       const formData = new FormData();

  //       formData.append('content', content ? content : ' ');
  //       formData.append('project_id', Number(id).toString());

  //       const res = await addProjectComment(appAxios, formData);
  //       if (res?.data) {
  //         setContent('');
  //         setFiles([]);
  //         setIsCommenting(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  return (
    <div className="add-news-main">
      <div className="add-news-container">
        <h3 className="px-2 py-2 mb-0">{isAdnew ? 'Add News' : 'Edit News'}</h3>
        <div className="news-form-container">
          <div className="field-container">
            <label htmlFor="news-title">Title</label>
            <input
              autoComplete="off"
              onChange={(e) => handleChangeValue(e, 'title')}
              value={formValues.title}
              type="text"
              id="news-title"
              placeholder="4139-VD-PR23.023 Korbmacher Stift. Köln"
            />
          </div>
          <div className="editor-container">
            <label>Content</label>
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ['clean'],
                ],
              }}
            ></ReactQuill>
          </div>
          <div className="button-container">
            <Button
              onClick={handleAddNews}
              padding="2px 12px"
              borderRadius="5px"
              border="none"
            >
              {isAdnew ? 'Add News' : 'Update News'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNews;
