import './_add-request.scss';
import { useEffect, useState, useRef } from 'react';
import ThankPage from '../../components/ThankPage';
import VStore from '../../store';
import Page from '../../components/FormPages';
import { IPageName } from '../../interfaces';
import SendMail from '../../components/send-mail/send-mail';

function AddRequest() {
  // interface GenericIdentityFn {
  //   <Type>(arg: Type): Type;
  // }
  //
  // function identity<Type>(arg: Type): Type {
  //   return arg;
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const myIdentity: GenericIdentityFn = identity;

  const {
    increasePageIdx,
    decreasePageIdx,
    setCurPageIdx,
    completedForm,
    simpleData,
    curPageIdx,
    data,
  } = VStore();

  const [isValid, SetValid] = useState<boolean>(false);
  const [showZurUbersicht, setShowZurUbersicht] = useState<boolean>(false);
  const SendMailRef = useRef(null);
  const maxPageNum = 16;

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkValid = (pageIdx?: number): boolean => {
    const curPage = ('page' + (pageIdx || curPageIdx)) as IPageName;
    if (curPageIdx === 1.1) {
      return !!(
        simpleData.project_name.trim() && simpleData.description.trim()
      );
    } else if (curPage === 'page2') {
      return !!(data[curPage].plz && data[curPage].ort);
    } else if (curPage === 'page10') {
      const { type, que, lan } = data[curPage];
      let isValid = !!type;
      if (typeof que === 'string') {
        isValid = !!(que && lan) && (que && lan) !== '0';
      }
      return isValid;
    } else if (curPage === 'page15') {
      const {
        vorname,
        nachname,
        plz,
        ort,
        email,
        telefon,
        rechnungsadresse,
        r_plz,
        r_ort,
      } = data[curPage];

      return !!(
        vorname &&
        nachname &&
        plz &&
        ort &&
        validateEmail(email) &&
        telefon &&
        (rechnungsadresse ? r_plz && r_ort : true)
      );
    } else if (
      [
        'page1',
        'page3',
        'page4',
        'page5',
        'page6',
        'page7',
        'page8',
        'page10',
        'page12',
      ].includes(curPage)
    ) {
      return !!data[curPage];
    }
    return true;
  };

  useEffect(() => {
    SetValid(checkValid());
    if (curPageIdx === maxPageNum) {
      setShowZurUbersicht(true);
    }
  }, [data, curPageIdx, simpleData]);

  const checkAllValid = () => {
    let _curPageIdx = curPageIdx;
    while (_curPageIdx < maxPageNum) {
      if (checkValid(_curPageIdx)) {
        _curPageIdx++;
      } else {
        break;
      }
    }
    setCurPageIdx(_curPageIdx);
  };

  useEffect(
    () => {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = ''; // Required for some browsers
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    },
    [
      // navigate, location
    ]
  );

  return (
    <div className="vm-app">
      {completedForm ? (
        <ThankPage />
      ) : (
        <>
          <Page />
          <footer>
            <div className="footer-container d-flex flex-sm-row justify-content-center mb-3">
              <button disabled={curPageIdx === 1} onClick={decreasePageIdx}>
                Zurück
              </button>
              <button
                disabled={
                  !isValid || curPageIdx === maxPageNum || curPageIdx === 1.1
                }
                onClick={increasePageIdx}
                className={'highlighted-btn'}
              >
                Nächste
              </button>
              {curPageIdx !== maxPageNum && curPageIdx !== 1.1 ? (
                <button
                  disabled={!isValid}
                  className={
                    'highlighted-btn' + (showZurUbersicht ? '' : ' d-none')
                  }
                  onClick={checkAllValid}
                >
                  Zur Übersicht
                </button>
              ) : (
                <button
                  disabled={!isValid}
                  className={'highlighted-btn'}
                  onClick={() => {
                    const current = SendMailRef.current as any;
                    current.sendForm();
                  }}
                  // onClick={
                  //   curPageIdx === 1.1
                  //     ? () => console.log('send form')
                  //     : () => {
                  //         const current = SendMailRef.current as any;
                  //         current.sendForm();
                  //       }
                  // }
                >
                  Senden
                </button>
              )}
            </div>
            <div className="pg-bar">
              <div
                style={{ width: (curPageIdx / maxPageNum) * 100 + '%' }}
              ></div>
            </div>
          </footer>
          <SendMail ref={SendMailRef}></SendMail>
        </>
      )}
    </div>
  );
}

export default AddRequest;
