import { useNavigate } from 'react-router-dom';
import './_news.scss';
import { dateToPopularString } from '../../services/utils';
import { newsPosted } from '../../_data';
import { Button } from '../../components';

function News() {
  const navigate = useNavigate();
  const goToEdittingNews = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    nav: string
  ) => {
    e.stopPropagation();
    navigate(nav);
  };

  return (
    <div className="news">
      <div className="news-container">
        <div className="news-header pt-2 ps-2 d-flex justify-content-between align-items-center">
          <h3>NEWS</h3>
          <Button
            linkTo="/admin/news/add-news"
            padding="2px 12px"
            borderRadius="5px"
            border="none"
          >
            Add News
          </Button>
        </div>
        {/* <hr className="m-0" /> */}
        <div className="list-news-container">
          {new Array(14).fill(0).map(() => {
            return (
              <div key={Math.random()} className="news-item-container">
                <div
                  onClick={(e) => goToEdittingNews(e, `/news/{newsId}`)}
                  className="news-item"
                >
                  <div className="news-item--image">
                    <img src={newsPosted.imgUrl} alt="news" />
                  </div>
                  <div className="news-item--info">
                    <span>{newsPosted.name}</span>
                    <div className="content-snippet-container">
                      {newsPosted.content}
                    </div>
                    <span className="posted-date">
                      <span>Posted date: &nbsp;</span>
                      <span>{dateToPopularString(newsPosted.postedDate)}</span>
                    </span>
                  </div>
                  <div
                    onClick={(e) =>
                      goToEdittingNews(e, `/admin/news/edit-news/${1}`)
                    }
                    className="button-edit"
                  >
                    edit
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default News;
