import Page1 from './page-1';
import Page1_1 from './page-1-1';
import Page2 from './page-2';
import Page3 from './page-3';
import Page4 from './page-4';
import Page5 from './page-5';
import Page6 from './page-6';
import Page7 from './page-7';
import Page8 from './page-8';
import Page9 from './page-9';
import Page10 from './page-10';
import Page12 from './page-12';
import Page13 from './page-13';
import Page14 from './page-14';
import Page15 from './page-15';
import Page16 from './page-16';

const Pages = {
  Page1,
  Page1_1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9,
  Page10,
  Page12,
  Page13,
  Page14,
  Page15,
  Page16,
};

export { Pages };
