import { Form } from 'react-bootstrap';
import useVMStore from '../../../../store';

export default function Page8() {
  const name = 'page8';

  const { data, setData, showDetailModel } = useVMStore();

  const value = [
    {
      label: 'Standard',
      val: 'Standard',
      imgSrc: './assets/images/standard_gel.webp',
    },
    {
      label: 'Premium',
      val: 'Premium',
      imgSrc: './assets/images/premium_gel.webp',
    },
  ];

  return (
    <Form className="d-flex justify-content-center flex-column flex-md-row">
      {value.map((v) => (
        <Form.Group
          className="d-flex flex-column align-items-center pb-3 pb-md-0 col-12 col-md-6 col-lg-4"
          key={'page_8_op' + v.val}
          controlId={'page_8_op_' + v.label}
        >
          <label onClick={() => showDetailModel(v.imgSrc)}>
            <img
              className="expand-btn"
              src="./assets/images/expand.svg"
              alt="expand"
            />
            <img src={v.imgSrc} alt={v.val} />
          </label>
          <Form.Check
            type="radio"
            className="mt-3"
            label={v.label}
            value={v.val}
            checked={data[name] === v.val}
            onChange={(e) => {
              setData(e.target.value);
            }}
          />
        </Form.Group>
      ))}
    </Form>
  );
}
